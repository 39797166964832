import { useEffect, useState } from "react";
import { BenchmarkProposal, BenchmarkProposalProductAdjustment, ProductAdjustmentStatus } from "../../../../services/benchmark-proposal.service";
import { ProductMarket } from "../../../../services/product.service";
import { compareProductAdjustmentsByStatus } from "../../../../helpers/benchmark-proposal";

function useProductAdjustments(benchmarkProposal: BenchmarkProposal) {
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [market, setMarket] = useState<ProductMarket | 'all'>('all');
  const [status, setStatus] = useState<ProductAdjustmentStatus | 'all'>('all');
  const [page, setPage] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(1);

  const [productAdjustments, setProductAdjustments] = useState<BenchmarkProposalProductAdjustment[]>([]);

  /**
   * A handler for when the search query changes.  
   * This will set the market and status to 'all' and then update the search query.
   * @param newQuery The new search query.
   */
  function handleSearchQueryChange(newQuery: string) {
    setMarket('all');
    setStatus('all');
    setSearchQuery(newQuery);
  }

  useEffect(() => {
    setPage(1);
  }, [searchQuery, market, status]);

  // Update the product adjustments when the searchQuery, market or status changes
  useEffect(() => {
    // Filter the product adjustments by searchQuery, market and status
    const adjustmentsWithStatus = benchmarkProposal.productAdjustments.filter(adjustment => {
      const matchesSearchQuery =
        !searchQuery ||
        adjustment.product.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
        adjustment.product.ref.toLowerCase().startsWith(searchQuery.toLowerCase());

      if (!matchesSearchQuery) {
        return false;
      }

      if (market !== 'all' && adjustment.product.mkt1 !== market) {
        return false;
      }

      if (status !== 'all' && adjustment.status !== status) {
        return false;
      }

      return true;
    });

    // Sort the product adjustments by status
    adjustmentsWithStatus.sort(compareProductAdjustmentsByStatus);

    setProductAdjustments(adjustmentsWithStatus);
    setPageCount(Math.ceil(adjustmentsWithStatus.length / 10));
  }, [searchQuery, market, status, benchmarkProposal]);

  return {
    searchQuery,
    handleSearchQueryChange,
    market,
    setMarket,
    status,
    setStatus,
    page,
    setPage,
    productAdjustments: productAdjustments.slice((page - 1) * 10, page * 10),
    pageCount,
  }
}

export default useProductAdjustments;
