import { fileBase64ToBlob } from '../../../../helpers/photos';
import {
  useCreateBenchmarkStatementMutation,
  useLazyGetStatementUploadPhotoUrlQuery,
} from '../../../../services/benchmark-statement.service';
import { useAppSelector } from '../../../../store';
import { CreateStatementFormState } from '../../../../store/slices/create-statement-form.slice';

function useSaveStatement() {
  const { user } = useAppSelector(state => state.auth);

  const [getStatementUploadUrl] = useLazyGetStatementUploadPhotoUrlQuery();
  const [createBenchmarkStatement] = useCreateBenchmarkStatementMutation();

  async function saveStatement(createStatementFormState: CreateStatementFormState) {
    // Upload the photos to S3.
    const photoKeys: string[] = [];
    for (const photo of createStatementFormState.photos) {
      const { data, error } = await getStatementUploadUrl(photo.type);
      if (error || !data) {
        continue;
      }

      const { signedUrl, key } = data;
      photoKeys.push(key);

      await fetch(signedUrl, {
        method: 'PUT',
        headers: { 'Content-Type': 'image/*' },
        body: fileBase64ToBlob(photo),
      });
    }

    return createBenchmarkStatement({
      salesId: user!.id,
      wholesalerId: createStatementFormState.wholesalerId!,
      wholesalerNetworkId: createStatementFormState.wholesalerNetworkId!,
      wholesalerSizeId: createStatementFormState.wholesalerSizeId!,
      benchmarkLayoutId: createStatementFormState.layoutBenchmarkId!,
      contact: createStatementFormState.contact!,
      contactPosition: createStatementFormState.contactPosition!,
      contactPhone: createStatementFormState.contactPhone!,
      contactEmail: createStatementFormState.contactEmail || undefined,
      trainingRequired: createStatementFormState.isTrainingRequired,
      trainingComments: createStatementFormState.trainingComments || undefined,
      publicPlacesMeters: createStatementFormState.publicPlacesMeters!,
      renovationMeters: createStatementFormState.renovationMeters!,
      accessMeters: createStatementFormState.accessMeters!,
      tabletsAvailable: createStatementFormState.areTabletsAvailable,
      perforedBottomsInstalled: createStatementFormState.arePerforedBottomsInstalled,
      loadBarsAvailable: createStatementFormState.arePerforedBottomsInstalled
        ? undefined
        : createStatementFormState.areLoadBarsAvailable,
      linearHeight: createStatementFormState.linearHeight,
      linearWidth: createStatementFormState.linearWidth,
      photoKeys: photoKeys,
      products: createStatementFormState.productStatements.map(stmt => ({
        productRef: stmt.product.ref,
        quantity: stmt.quantity,
      })),
      comments: createStatementFormState.comments || undefined,
      isPrivate: createStatementFormState.isPrivate,
    }).unwrap();
  }

  return saveStatement;
}

export default useSaveStatement;
