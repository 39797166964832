import { Document, Font, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { BenchmarkProposal } from '../../../services/benchmark-proposal.service';
import useTranslate from '../../../hooks/useTranslate';
import { getFullAddress } from '../../../helpers/wholesaler';
import { getFullName } from '../../../helpers/user';
import {
  compareProductAdjustmentsByStatus,
  formatAdjustmentQuantity,
  getAjustmentQuantityColor,
  getProposalRecapTitle,
} from '../../../helpers/benchmark-proposal';
import { isProductInMainMarkets } from '../../../helpers/product';

Font.register({
  family: 'Roboto',
  fonts: [
    { src: 'https://cdn.jsdelivr.net/npm/roboto-font@0.1.0/fonts/Roboto/roboto-regular-webfont.ttf', fontWeight: 400 },
    { src: 'https://cdn.jsdelivr.net/npm/roboto-font@0.1.0/fonts/Roboto/roboto-bold-webfont.ttf', fontWeight: 700 },
  ],
});

export interface BenchmarkProposalRecapProps {
  proposal: BenchmarkProposal;
}

function BenchmarkProposalRecap({ proposal }: BenchmarkProposalRecapProps) {
  const { t, localeDate } = useTranslate();

  const adjustmentsWithStatus = [...proposal.productAdjustments];
  adjustmentsWithStatus.sort(compareProductAdjustmentsByStatus);

  const publicPlacesAdjustments = adjustmentsWithStatus.filter(
    adjustment => adjustment.product.mkt1 === 'public_places',
  );
  const renovationAdjustments = adjustmentsWithStatus.filter(adjustment => adjustment.product.mkt1 === 'renovation');
  const accessAdjustments = adjustmentsWithStatus.filter(adjustment => adjustment.product.mkt1 === 'access');
  const otherAdjustments = adjustmentsWithStatus.filter(adjustment => !isProductInMainMarkets(adjustment.product));

  const tables = [
    { title: t('market.public_places'), adjustments: publicPlacesAdjustments },
    { title: t('market.renovation'), adjustments: renovationAdjustments },
    { title: t('market.access'), adjustments: accessAdjustments },
    { title: t('market.others'), adjustments: otherAdjustments },
  ];

  return (
    <Document title={getProposalRecapTitle(proposal)}>
      <Page size="A4" orientation="landscape" style={styles.page}>
        <View style={styles.titleContainer}>
          <Text style={styles.title}>{t('benchmark_proposal.title_accepted')}</Text>
          <Text style={styles.subtitle}>
            {t('statement.date', { date: localeDate(proposal.benchmarkStatement.createdAt, 'short') })}
          </Text>
        </View>
        <View style={styles.infoTable}>
          <View style={styles.infoTableRow}>
            <View style={styles.infoTable}>
              <Text style={styles.infoTableRowHeader}>{t('user.role.sales')}</Text>
            </View>
            <View style={styles.infoTable}>
              <Text style={styles.infoTableCell}>{getFullName(proposal.sales)}</Text>
            </View>
          </View>
          <View style={styles.infoTableRow}>
            <View style={styles.infoTable}>
              <Text style={styles.infoTableRowHeader}>{t('wholesaler.id')}</Text>
            </View>
            <View style={styles.infoTable}>
              <Text style={styles.infoTableCell}>{proposal.wholesaler.id}</Text>
            </View>
          </View>
          <View style={styles.infoTableRow}>
            <View style={styles.infoTable}>
              <Text style={styles.infoTableRowHeader}>{t('wholesaler.name')}</Text>
            </View>
            <View style={styles.infoTable}>
              <Text style={styles.infoTableCell}>{proposal.wholesaler.name}</Text>
            </View>
          </View>
          <View style={styles.infoTableRow}>
            <View style={styles.infoTable}>
              <Text style={styles.infoTableRowHeader}>{t('wholesaler.address')}</Text>
            </View>
            <View style={styles.infoTable}>
              <Text style={styles.infoTableCell}>{getFullAddress(proposal.wholesaler)}</Text>
            </View>
          </View>
          <View style={styles.infoTableRow}>
            <View style={styles.infoTable}>
              <Text style={styles.infoTableRowHeader}>{t('benchmark_proposal.scheduled_date')}</Text>
            </View>
            <View style={styles.infoTable}>
              <Text style={styles.infoTableCell}>
                {proposal.scheduledInstallationDate ? localeDate(proposal.scheduledInstallationDate, 'short') : '-'}
              </Text>
            </View>
          </View>
        </View>

        {tables.map((table, index) => {
          if (table.adjustments.length === 0) return null;

          return (
            <View key={table.title} style={styles.tableContainer} break={index !== 0}>
              <Text style={styles.tableTitle}>{table.title}</Text>
              <View style={styles.table}>
                <View style={styles.tableRow}>
                  <View style={[styles.tableColHeader, { width: '15%' }]}>
                    <Text style={styles.tableCellHeader}>{t('product_adjustment.product_ref')}</Text>
                  </View>
                  <View style={[styles.tableColHeader, { width: '30%' }]}>
                    <Text style={styles.tableCellHeader}>{t('product_adjustment.product_name')}</Text>
                  </View>
                  <View style={[styles.tableColHeader, { width: '25%' }]}>
                    <Text style={styles.tableCellHeader}>{t('global.status')}</Text>
                  </View>
                  <View style={[styles.tableColHeader, { width: '10%' }]}>
                    <Text style={styles.tableCellHeader}>{t('product_adjustment.current_quantity_short')}</Text>
                  </View>
                  <View style={[styles.tableColHeader, { width: '10%' }]}>
                    <Text style={styles.tableCellHeader}>{t('product_adjustment.adjustment')}</Text>
                  </View>
                  <View style={[styles.tableColHeader, { width: '10%' }]}>
                    <Text style={styles.tableCellHeader}>{t('product_adjustment.minimum_quantity_short')}</Text>
                  </View>
                </View>
                {table.adjustments.map(adjustment => {
                  const statementProduct = proposal.benchmarkStatement.products.find(
                    item => item.product.ref === adjustment.product.ref,
                  );

                  const layoutProduct = proposal.benchmarkStatement.benchmarkLayout.products.find(
                    item => item.product.ref === adjustment.product.ref,
                  );

                  return (
                    <View key={adjustment.id} style={styles.tableRow}>
                      <View style={[styles.tableCol, { width: '15%' }]}>
                        <Text style={styles.tableCell}>{adjustment.product.ref}</Text>
                      </View>
                      <View style={[styles.tableCol, { width: '30%' }]}>
                        <Text style={styles.tableCell}>{adjustment.product.title}</Text>
                      </View>
                      <View style={[styles.tableCol, { width: '25%' }]}>
                        <Text style={styles.tableCell}>
                          {t(`product_adjustment.status.${adjustment.status}`).toUpperCase()}
                        </Text>
                      </View>
                      <View style={[styles.tableCol, { width: '10%' }]}>
                        <Text style={styles.tableCell}>{statementProduct?.quantity ?? 0}</Text>
                      </View>
                      <View style={[styles.tableCol, { width: '10%' }]}>
                        <Text style={styles.tableCell}>
                          {formatAdjustmentQuantity(adjustment.quantityAdjustment, '0')}
                        </Text>
                      </View>
                      <View style={[styles.tableCol, { width: '10%' }]}>
                        <Text style={styles.tableCell}>{layoutProduct?.minimumQuantity ?? 'N/A'}</Text>
                      </View>
                    </View>
                  );
                })}
              </View>
            </View>
          );
        })}
      </Page>
    </Document>
  );
}

const styles = StyleSheet.create({
  page: {
    padding: 40,
    fontFamily: 'Roboto',
  },
  titleContainer: {
    marginTop: 5,
    marginBottom: 10,
  },
  title: {
    fontSize: 16,
    textAlign: 'center',
    marginBottom: 5,
  },
  subtitle: {
    fontSize: 14,
    textAlign: 'center',
    color: '#494949',
  },
  infoTable: {
    display: 'flex',
    flexDirection: 'column',
  },
  infoTableRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  infoTableRowHeader: {
    fontSize: 13,
    fontWeight: 700,
  },
  infoTableCell: {
    fontSize: 13,
  },
  tableContainer: {
    marginTop: 20,
  },
  tableTitle: {
    fontSize: 14,
    marginBottom: 5,
  },
  table: {
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
  },
  tableColHeader: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: '#E4E4E4',
  },
  tableCol: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCellHeader: {
    margin: 5,
    fontSize: 12,
    fontWeight: 500,
  },
  tableCell: {
    margin: 5,
    fontSize: 10,
  },
});

export default BenchmarkProposalRecap;
