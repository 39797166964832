import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import Config from '../config';
import { prepareHeaders } from '../helpers/services';
import { Paginated } from './types';
import { Wholesaler } from './wholesaler.service';

export type ProductMarket = 'public_places' | 'access' | 'renovation' | 'stainless_steel_sanitary' | 'professional_kitchens' | 'hospitals';

export interface Product {
  ref: string;
  barcodeRef: string;
  title: string;
  mkt1: ProductMarket;
  imageUrl: string | null;
}

export interface ProductStock {
  wholesaler: Wholesaler;
  stock: number;
}

export const productApi = createApi({
  reducerPath: 'productApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${Config.apiBaseUrl}/product`, prepareHeaders }),
  tagTypes: ['Products'],
  endpoints: (builder) => ({
    getProductByRef: builder.query<Product, string>({
      query: (ref: string) => `/${ref}`,
    }),
    getProducts: builder.query<Paginated<Product>, { page?: number, query?: string } | void>({
      query: (params) => {
        const searchParams = new URLSearchParams();
        if (params?.page) searchParams.append('page', params.page.toString());
        if (params?.query) searchParams.append('q', params.query);
        return `?${searchParams.toString()}`;
      },
      providesTags: (result) =>
        result
          ? [
            ...result.edges.map(({ ref }) => ({ type: 'Products' as const, ref })),
            { type: 'Products', ref: 'PARTIAL-LIST' },
          ]
          : [{ type: 'Products', ref: 'PARTIAL-LIST' }],
    }),
    getAllProducts: builder.query<Product[], void>({
      query: () => '/all',
      providesTags: (result) => result ? [...result.map(({ ref }) => ({ type: 'Products' as const, ref }))] : [],
    }),
    getProductStockByRef: builder.query<ProductStock[], string>({
      query: (ref: string) => `/${ref}/stock`,
    }),
  }),
})

export const {
  useGetProductByRefQuery,
  useLazyGetProductByRefQuery,
  useGetProductsQuery,
  useLazyGetProductsQuery,
  useGetAllProductsQuery,
  useLazyGetAllProductsQuery,
  useGetProductStockByRefQuery,
  useLazyGetProductStockByRefQuery,
} = productApi;