import { useEffect } from 'react';
import {
  Autocomplete,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import useTranslate from '../../../../../hooks/useTranslate';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { setCreateStatementFormState } from '../../../../../store/slices/create-statement-form.slice';

function CreateStatementStep2() {
  const { t } = useTranslate();

  const dispatch = useAppDispatch();
  const {
    wholesalerId,
    wholesalerNetworkId,
    wholesalerSizeId,
    layoutBenchmarkId,
    contact,
    contactPosition,
    contactPhone,
    contactEmail,
    isTrainingRequired,
    trainingComments,
  } = useAppSelector(state => state.createStatementForm);

  const { wholesalers, wholesalerNetworks, layoutBenchmarks, formSuggestions } = useAppSelector(state => state.offline);

  const wholesaler = wholesalers.find(wholesaler => wholesaler.id === wholesalerId);
  const wholesalerSizes = wholesalerNetworks?.find(network => network.id === wholesalerNetworkId)?.sizes ?? [];

  // Set the layout benchmark if the wholesaler network and size are selected
  useEffect(() => {
    if (!wholesalerNetworkId || !wholesalerSizeId || layoutBenchmarkId) return;
    const layoutBenchmark = layoutBenchmarks?.find(
      benchmark => benchmark.network.id === wholesalerNetworkId && benchmark.size.id === wholesalerSizeId,
    );
    if (layoutBenchmark) {
      dispatch(setCreateStatementFormState({ layoutBenchmarkId: layoutBenchmark.id }));
    }
  }, [wholesalerNetworkId, wholesalerSizeId, layoutBenchmarkId, layoutBenchmarks]);

  return (
    <Stack direction="column" spacing={2}>
      <Paper elevation={1}>
        <Stack direction="column" spacing={4} p={2}>
          <FormControl fullWidth>
            <InputLabel id="wholesaler-network-label">{t('statement.create.form.network_label')} *</InputLabel>
            <Select
              id="wholesaler-network"
              label={`${t('statement.create.form.network_label')}} *`}
              labelId="wholesaler-network-label"
              value={wholesalerNetworkId ? wholesalerNetworkId.toString() : ''}
              onChange={evt =>
                dispatch(setCreateStatementFormState({ wholesalerNetworkId: parseInt(evt.target.value) }))
              }
              required>
              {wholesalerNetworks?.map(network => (
                <MenuItem key={network.id} value={network.id}>
                  {network.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="wholesaler-size-label">{t('statement.create.form.size_label')} *</InputLabel>
            <Select
              id="wholesaler-size"
              label={`${t('statement.create.form.network_label')} *`}
              labelId="wholesaler-size-label"
              value={wholesalerSizeId ? wholesalerSizeId.toString() : ''}
              onChange={evt => dispatch(setCreateStatementFormState({ wholesalerSizeId: parseInt(evt.target.value) }))}
              required>
              {wholesalerSizes.length === 0 && (
                <MenuItem disabled value="">
                  <em>{t('statement.error.select_network_first')}</em>
                </MenuItem>
              )}
              {wholesalerSizes.map(size => (
                <MenuItem key={size.id} value={size.id}>
                  {size.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormGroup>
            <FormLabel sx={{ marginBottom: 1 }}>{t('statement.create.form.benchmark_layout_label')} :</FormLabel>
            <FormControl fullWidth>
              <Select
                id="wholesaler-size"
                labelId="wholesaler-size-label"
                value={layoutBenchmarkId ? layoutBenchmarkId.toString() : ''}
                onChange={evt =>
                  dispatch(setCreateStatementFormState({ layoutBenchmarkId: parseInt(evt.target.value) }))
                }
                displayEmpty
                required>
                <MenuItem disabled value="">
                  <em>{t('layout.benchmark')}</em>
                </MenuItem>
                {layoutBenchmarks?.map(benchmark => (
                  <MenuItem key={benchmark.id} value={benchmark.id}>
                    {benchmark.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </FormGroup>
        </Stack>
      </Paper>

      <Paper elevation={0}>
        <Stack direction="column" spacing={2}>
          <Autocomplete
            id="contact"
            options={wholesaler?.contacts.map(c => `${c.firstName} ${c.lastName}`) ?? []}
            renderInput={params => <TextField {...params} label={t('statement.create.form.contact_label')} required />}
            inputValue={contact}
            onChange={(_, value) => {
              if (value === null) {
                dispatch(setCreateStatementFormState({ contact: '' }));
                dispatch(setCreateStatementFormState({ contactPosition: '' }));
                dispatch(setCreateStatementFormState({ contactPhone: '' }));
                dispatch(setCreateStatementFormState({ contactEmail: '' }));
                return;
              }

              const contact = wholesaler?.contacts.find(c => `${c.firstName} ${c.lastName}` === value);
              if (contact) {
                dispatch(setCreateStatementFormState({ contact: value }));
                dispatch(setCreateStatementFormState({ contactPosition: contact.position ?? '' }));
                dispatch(setCreateStatementFormState({ contactPhone: contact.phone ?? '' }));
                dispatch(setCreateStatementFormState({ contactEmail: contact.email ?? '' }));
              }
            }}
            onInputChange={(_, value) => {
              dispatch(setCreateStatementFormState({ contact: value ?? '' }));
            }}
            freeSolo
          />
          <Autocomplete
            id="position"
            options={formSuggestions.contactPosition.map(s => s.value) ?? []}
            renderInput={params => (
              <TextField {...params} label={t('statement.create.form.contact_position_label')} required />
            )}
            inputValue={contactPosition}
            onInputChange={(_, value) => dispatch(setCreateStatementFormState({ contactPosition: value ?? '' }))}
            freeSolo
          />
          <TextField
            id="phone-number"
            type="tel"
            label={t('statement.create.form.contact_phone_label')}
            variant="outlined"
            placeholder="+33 00 00 00 00 00"
            value={contactPhone}
            onChange={evt => dispatch(setCreateStatementFormState({ contactPhone: evt.target.value }))}
            required
          />
          <TextField
            id="email"
            type="email"
            label={t('statement.create.form.contact_email_label')}
            variant="outlined"
            placeholder="mail@mail.com"
            value={contactEmail}
            onChange={evt => dispatch(setCreateStatementFormState({ contactEmail: evt.target.value }))}
          />
        </Stack>
      </Paper>

      <Paper elevation={1}>
        <Stack direction="column" p={2}>
          <Typography variant="overline" component="h3">
            {t('statement.create.form.required_training_label')}
          </Typography>
          <FormControl>
            <RadioGroup
              name="is-training-required"
              value={isTrainingRequired ? 'yes' : 'no'}
              onChange={evt =>
                dispatch(setCreateStatementFormState({ isTrainingRequired: evt.target.value === 'yes' }))
              }
              row>
              <FormControlLabel value="yes" control={<Radio />} label={t('global.yes')} />
              <FormControlLabel value="no" control={<Radio />} label={t('global.no')} />
            </RadioGroup>
          </FormControl>
          {isTrainingRequired && (
            <TextField
              id="training-comments"
              label={t('statement.create.form.required_training_comments')}
              variant="outlined"
              value={trainingComments}
              onChange={evt => dispatch(setCreateStatementFormState({ trainingComments: evt.target.value }))}
              minRows={3}
              multiline
            />
          )}
        </Stack>
      </Paper>
    </Stack>
  );
}

export default CreateStatementStep2;
