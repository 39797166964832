import { Wholesaler } from "../services/wholesaler.service";
import { getTotalNumberOfLinearMeters } from "./intervention-report";

export type WholesalerSortMethod =
  | 'name'
  | 'last_benchmark_date_asc'
  | 'last_benchmark_date_desc'
  | 'linear_meters_asc'
  | 'linear_meters_desc';

/**
 * Get the full address of a wholesaler.
 * @param wholesaler The wholesaler to get the full address from.
 * @returns The full address of the wholesaler.
 */
export function getFullAddress(wholesaler: Wholesaler) {
  return `${wholesaler.address1}, ${wholesaler.address2 ? `${wholesaler.address2}, ` : ""}${wholesaler.zip} ${wholesaler.city}`;
}

/**
 * Search a list of wholesalers.
 * @param query The query to search.
 * @param wholesalers The wholesalers to search in.
 * @returns The list of wholesalers that match the query.
 */
export function searchWholesalers(query: string, wholesalers: Wholesaler[]): Wholesaler[] {
  if (query.length === 0) return wholesalers;

  return wholesalers.filter(wholesaler => (
    wholesaler.id.toLowerCase().includes(query.toLowerCase()) ||
    wholesaler.name.toLowerCase().includes(query.toLowerCase()) ||
    wholesaler.name2?.toLowerCase().includes(query.toLowerCase()) ||
    wholesaler.address1.toLowerCase().includes(query.toLowerCase()) ||
    wholesaler.address2?.toLowerCase().includes(query.toLowerCase()) ||
    wholesaler.zip.toLowerCase().includes(query.toLowerCase()) ||
    wholesaler.city.toLowerCase().includes(query.toLowerCase()) ||
    wholesaler.country.toLowerCase().includes(query.toLowerCase())
  ));
}

/**
 * Sort a list of wholesalers.
 * @param wholesalers The wholesalers to sort.
 * @param sortBy The sort method to use.
 * @returns The sorted wholesalers.
 */
export function sortWholesalers(wholesalers: Wholesaler[], sortBy: WholesalerSortMethod): Wholesaler[] {
  if (sortBy === 'name') {
    return wholesalers.sort((a, b) => a.name.localeCompare(b.name));
  }

  if (sortBy === 'last_benchmark_date_asc') {
    return wholesalers.sort((a, b) => {
      const aLastBenchmark = new Date(a.interventionReports[0]?.createdAt ?? 0);
      const bLastBenchmark = new Date(b.interventionReports[0]?.createdAt ?? 0);

      if (aLastBenchmark.getTime() === bLastBenchmark.getTime()) {
        return a.name.localeCompare(b.name);
      }

      return aLastBenchmark.getTime() - bLastBenchmark.getTime();
    });
  }

  if (sortBy === 'last_benchmark_date_desc') {
    return wholesalers.sort((a, b) => {
      const aLastBenchmark = new Date(a.interventionReports[0]?.createdAt ?? 0);
      const bLastBenchmark = new Date(b.interventionReports[0]?.createdAt ?? 0);

      if (aLastBenchmark.getTime() === bLastBenchmark.getTime()) {
        return a.name.localeCompare(b.name);
      }

      return bLastBenchmark.getTime() - aLastBenchmark.getTime();
    });
  }

  if (sortBy === 'linear_meters_asc') {
    return wholesalers.sort((a, b) => {
      const aLinearMeters = a.interventionReports[0] ? getTotalNumberOfLinearMeters(a.interventionReports[0]) : -1;
      const bLinearMeters = b.interventionReports[0] ? getTotalNumberOfLinearMeters(b.interventionReports[0]) : -1;

      if (aLinearMeters === bLinearMeters) {
        return a.name.localeCompare(b.name);
      }

      return aLinearMeters - bLinearMeters;
    });
  }

  if (sortBy === 'linear_meters_desc') {
    return wholesalers.sort((a, b) => {
      const aLinearMeters = a.interventionReports[0] ? getTotalNumberOfLinearMeters(a.interventionReports[0]) : -1;
      const bLinearMeters = b.interventionReports[0] ? getTotalNumberOfLinearMeters(b.interventionReports[0]) : -1;

      if (aLinearMeters === bLinearMeters) {
        return a.name.localeCompare(b.name);
      }

      return bLinearMeters - aLinearMeters;
    });
  }

  return wholesalers;
}