import {
  Alert,
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Paper,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import useTranslate from '../../../../../hooks/useTranslate';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { setCreateStatementFormState } from '../../../../../store/slices/create-statement-form.slice';

function CreateStatementStep6() {
  const { t } = useTranslate();

  const dispatch = useAppDispatch();
  const { comments, isPrivate } = useAppSelector(state => state.createStatementForm);

  return (
    <Paper elevation={1}>
      <Box p={2}>
        <TextField
          id="comments"
          label={t('statement.create.comments_label')}
          variant="outlined"
          value={comments}
          onChange={evt => dispatch(setCreateStatementFormState({ comments: evt.target.value }))}
          minRows={6}
          multiline
          fullWidth
        />
      </Box>
      <Box p={2}>
        <FormControl>
          <FormLabel id="visibility-radio-label">{t('statement.type')}</FormLabel>
          <RadioGroup
            aria-labelledby="visibility-radio-label"
            value={isPrivate ? 'private' : 'normal'}
            onChange={evt => dispatch(setCreateStatementFormState({ isPrivate: evt.target.value === 'private' }))}
            name="visibility-radio">
            <FormControlLabel value="normal" control={<Radio />} label={t('statement.type.classic')} />
            {!isPrivate && <Alert severity="info">{t('statement.type.classic.description')}</Alert>}
            <FormControlLabel value="private" control={<Radio />} label={t('statement.type.private')} />
            {isPrivate && <Alert severity="info">{t('statement.type.private.description')}</Alert>}
          </RadioGroup>
        </FormControl>
      </Box>
    </Paper>
  );
}

export default CreateStatementStep6;
