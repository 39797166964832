import { useEffect, useState } from 'react';
import useTranslate from '../../../../../hooks/useTranslate';
import { useAppSelector } from '../../../../../store';
import { Alert, AlertColor, Backdrop, CircularProgress, Snackbar } from '@mui/material';
import { Product } from '../../../../../services/product.service';
import { useDispatch } from 'react-redux';
import BarcodeScanner from '../../../../../components/others/BarcodeScanner';
import ScanResultDrawer from '../../../../../components/product-statement/ScanResultDrawer';
import ProductStatementListDrawer from '../../../../../components/product-statement/ProductStatementListDrawer';
import { setCreateReportFormState } from '../../../../../store/slices/create-report-form.slice';

export interface CreateReportStep3Props {
  showProductList?: boolean;
  onHideProductList?: () => void;
}

function CreateReportStep3({ showProductList, onHideProductList }: CreateReportStep3Props) {
  const { t } = useTranslate();

  const dispatch = useDispatch();
  const { productStatements } = useAppSelector(state => state.createReportForm);
  const { products } = useAppSelector(state => state.offline);

  const [barcodeRef, setBarcodeRef] = useState<string | null>(null);

  const [snackbarMessage, setSnackbarMessage] = useState<string | null>(null);
  const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor>('info');

  const [showBottomDrawer, setShowBottomDrawer] = useState<boolean>(false);
  const [showBackdrop, setShowBackdrop] = useState<boolean>(true);

  const [product, setProduct] = useState<Product | undefined>();

  /** Add or update a product in the statement. */
  function handleAddProduct(quantity: number) {
    if (!product) return;

    setShowBottomDrawer(false);

    setTimeout(() => {
      let message: string;
      const existingItem = productStatements.find(stmt => stmt.product.ref === product.ref);
      if (existingItem) {
        dispatch(
          setCreateReportFormState({
            productStatements: productStatements.map(stmt => {
              if (stmt.product.ref === existingItem.product.ref) {
                return { ...stmt, quantity };
              }
              return stmt;
            }),
          }),
        );

        message = t('statement.create.quantity_updated');
      } else {
        dispatch(
          setCreateReportFormState({
            productStatements: [...productStatements, { product: product, quantity }],
          }),
        );

        message = t('statement.create.product_added');
      }

      setSnackbarSeverity('success');
      setSnackbarMessage(message);
      setBarcodeRef(null);
    }, 200);
  }

  /** Remove a product from the statement. */
  function handleRemoveProduct() {
    if (!product) return;
    setShowBottomDrawer(false);
    setTimeout(() => {
      dispatch(
        setCreateReportFormState({
          productStatements: productStatements.filter(stmt => stmt.product.ref !== product.ref),
        }),
      );
      setSnackbarSeverity('success');
      setSnackbarMessage(t('statement.create.product_removed'));
      setBarcodeRef(null);
    }, 50);
  }

  // When the barcodeRef changes, fetch the product from the API.
  useEffect(() => {
    if (!barcodeRef) return;

    const product = products.find(p => p.barcodeRef === barcodeRef);
    setProduct(product);

    if (!product) {
      setSnackbarSeverity('warning');
      setSnackbarMessage(t('statement.create.no_product_for_barcode', { barcode: barcodeRef }));
      return;
    }

    setShowBottomDrawer(true);
  }, [barcodeRef]);

  return (
    <>
      <BarcodeScanner
        onUpdate={_barcodeRef => {
          setBarcodeRef(_barcodeRef);
        }}
        onReady={() => setShowBackdrop(false)}
      />

      <ScanResultDrawer
        visible={showBottomDrawer}
        product={product}
        productAlreadyAdded={productStatements.some(stmt => stmt.product.ref === product?.ref)}
        initialQuantity={productStatements.find(stmt => stmt.product.ref === product?.ref)?.quantity ?? 1}
        onClose={() => {
          setShowBottomDrawer(false);
          setBarcodeRef(null);
        }}
        onSubmit={quantity => handleAddProduct(quantity)}
        onRemove={handleRemoveProduct}
      />

      <ProductStatementListDrawer
        existingStatements={productStatements}
        visible={showProductList}
        onClose={onHideProductList}
        onItemClicked={item => {
          setBarcodeRef(item.product.barcodeRef);
        }}
        onAddProduct={productStatement => {
          dispatch(
            setCreateReportFormState({
              productStatements: [...productStatements, productStatement],
            }),
          );
        }}
      />

      <Snackbar
        open={!!snackbarMessage}
        autoHideDuration={1000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={() => {
          setSnackbarMessage(null);
          setBarcodeRef(null);
        }}>
        <Alert severity={snackbarSeverity} variant="filled" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={showBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

export default CreateReportStep3;
