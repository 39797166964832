import { FormLabel, Paper, Stack, TextField } from '@mui/material';
import useTranslate from '../../../../../hooks/useTranslate';
import PhotoInput from '../../../../../components/ui/form/PhotoInput';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { addPhoto, removePhoto, setCreateReportFormState } from '../../../../../store/slices/create-report-form.slice';

function CreateReportStep5() {
  const { t } = useTranslate();

  const dispatch = useAppDispatch();
  const { isImplementation, photos, comments } = useAppSelector(state => state.createReportForm);

  return (
    <>
      {isImplementation && (
        <Paper elevation={1} sx={{ mb: 2 }}>
          <Stack direction="column" spacing={2} p={2}>
            <FormLabel>{t('global.photos')}</FormLabel>
            <PhotoInput
              label={t('statement.create.form.add_photo')}
              infoMessage={t('statement.create.form.photos_label')}
              photos={photos}
              onSelectPhoto={photo => {
                dispatch(addPhoto(photo));
              }}
              onRemovePhoto={index => {
                dispatch(removePhoto(index));
              }}
            />
          </Stack>
        </Paper>
      )}
      <Paper elevation={1} sx={{ p: 2 }}>
        <TextField
          label={t('statement.create.comments_label')}
          variant="outlined"
          value={comments}
          onChange={evt => dispatch(setCreateReportFormState({ comments: evt.target.value }))}
          minRows={6}
          multiline
          fullWidth
        />
      </Paper>
    </>
  );
}

export default CreateReportStep5;
