import { Chip, Hidden, Stack, TableCell, TableRow, Typography } from '@mui/material';
import { getFullName } from '../../../../helpers/user';
import { BenchmarkProposalSummary } from '../../../../services/benchmark-proposal.service';
import { getProposalCoordinators, getStatusColor } from '../../../../helpers/benchmark-proposal';
import useTranslate from '../../../../hooks/useTranslate';

export interface BenchmarkProposalRowProps {
  proposal: BenchmarkProposalSummary;
}

function BenchmarkProposalRow({ proposal }: BenchmarkProposalRowProps) {
  const { t, localeDate } = useTranslate();

  const coordinators = getProposalCoordinators(proposal);

  let dateToDisplay = '-';
  let displayAsWarning = false;
  if (proposal.interventionReport?.createdAt) {
    dateToDisplay = localeDate(proposal.interventionReport.createdAt, 'short');
  } else if (proposal.scheduledInstallationDate) {
    displayAsWarning = true;
    dateToDisplay = t('benchmark_proposal.scheduled_on', {
      date: localeDate(proposal.scheduledInstallationDate, 'short'),
    });
  }

  return (
    <TableRow
      component="a"
      sx={{
        '&:last-child td, &:last-child th': { border: 0 },
        textDecoration: 'none',
        userSelect: 'none',
      }}
      href={`/benchmark-proposal/${proposal.id}`}>
      <TableCell>{localeDate(proposal.createdAt, 'short')}</TableCell>
      <Hidden smDown>
        <TableCell>
          <Typography
            variant="body2"
            color={displayAsWarning ? 'warning.main' : 'inherit'}
            fontWeight={displayAsWarning ? '500' : 'regular'}>
            {dateToDisplay}
          </Typography>
        </TableCell>
        <TableCell>{getFullName(proposal.sales)}</TableCell>
        <TableCell>{coordinators.length > 0 ? coordinators.map(c => getFullName(c)).join(' / ') : '-'}</TableCell>
        <TableCell>{proposal.wholesaler.name}</TableCell>
        <TableCell>{proposal.wholesaler.zip}</TableCell>
        <TableCell>{proposal.wholesaler.city}</TableCell>
      </Hidden>
      <TableCell>
        <Stack direction="row" spacing={1}>
          <Chip
            label={t(`benchmark_proposal.status.${proposal.state}`)}
            size="small"
            color={getStatusColor(proposal.state)}
          />
          {proposal.wholesaler.deletedAt !== null && <Chip label={t('global.archived')} size="small" color="error" />}
        </Stack>
      </TableCell>
    </TableRow>
  );
}

export default BenchmarkProposalRow;
