import { useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from '@mui/material';
import { Product, useGetProductsQuery } from '../../../../services/product.service';
import useTranslate from '../../../../hooks/useTranslate';
import ProductPresentation from '../../../../components/product/ProductPresentation';
import {
  BenchmarkProposal,
  useCreateBenchmarkProposalAdjustmentMutation,
} from '../../../../services/benchmark-proposal.service';

export interface AddAdjustmentDialogProps {
  /** If `true`, the component is shown. */
  open?: boolean;
  /** The proposal to add the adjustment to. */
  proposal: BenchmarkProposal;
  /** Callback fired when the component requests to be closed. */
  onClose?: () => void;
  /** Callback fired when the adjustment is created. */
  onAdjustmentCreated?: () => void;
}

function AddAdjustmentDialog({ open = false, proposal, onClose, onAdjustmentCreated }: AddAdjustmentDialogProps) {
  const { t } = useTranslate();

  const [query, setQuery] = useState<string>('');
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [quantity, setQuantity] = useState<number | null>(null);
  const [error, setError] = useState<string | null>(null);

  const { data: products } = useGetProductsQuery({ query: query ?? undefined });

  const [createBenchmarkProposalAdjustment] = useCreateBenchmarkProposalAdjustmentMutation();

  const isProductAlreadyAdded = proposal.productAdjustments.some(
    adjustment => adjustment.product.ref === selectedProduct?.ref,
  );

  function onSubmit(evt: React.FormEvent<HTMLFormElement>) {
    evt.preventDefault();
    evt.stopPropagation();

    if (!selectedProduct || quantity === null || isProductAlreadyAdded) return;

    createBenchmarkProposalAdjustment({
      benchmarkProposalId: proposal.id,
      productRef: selectedProduct.ref,
      quantity,
    })
      .unwrap()
      .then(() => {
        onAdjustmentCreated?.();
      })
      .catch(() => {
        setError(t('global.error.unknown'));
      });
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="add-adjustment-title"
      aria-describedby="add-adjustment-description"
      maxWidth="sm"
      fullWidth>
      <form onSubmit={onSubmit}>
        <DialogTitle id="add-adjustment-title">{t('product_adjustment.add')}</DialogTitle>
        <DialogContent>
          <Stack direction="column" spacing={2} mt={1}>
            <Box flexGrow={1}>
              <Autocomplete
                options={products?.edges ?? []}
                renderInput={params => (
                  <TextField
                    {...params}
                    label={t('product.search_ref')}
                    error={isProductAlreadyAdded || !!error}
                    helperText={isProductAlreadyAdded ? t('product_adjustment.error.existing') : error}
                    required
                  />
                )}
                renderOption={(props, option) => {
                  return (
                    <Box component="li" sx={{ cursor: 'pointer' }} {...props}>
                      <ProductPresentation product={option} />
                    </Box>
                  );
                }}
                getOptionLabel={option => option.ref}
                isOptionEqualToValue={(option, value) => option.ref === value.ref}
                filterOptions={x => x}
                noOptionsText={t('product.empty')}
                value={selectedProduct}
                onChange={(_, newValue) => setSelectedProduct(newValue)}
                inputValue={query}
                onInputChange={(_, newInputValue) => setQuery(newInputValue)}
                fullWidth
              />
            </Box>
            <TextField
              type="number"
              label={t('product_adjustment.value')}
              variant="outlined"
              value={quantity ?? ''}
              onChange={evt => setQuantity(evt.target.value === '' ? null : Number(evt.target.value))}
              required
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            {t('global.cancel')}
          </Button>
          <Button type="submit" variant="contained">
            {t('global.add')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default AddAdjustmentDialog;
