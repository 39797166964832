import { useState } from 'react';
import { Container } from '@mui/material';
import { useParams } from 'react-router-dom';
import NotFound from '../../NoFound';
import useTranslate from '../../../hooks/useTranslate';
import { useGetStatementQuery } from '../../../services/benchmark-statement.service';
import StatementHeader, { StatementTabType } from './components/StatementHeader';
import LayoutCard from './components/LayoutCard';
import StatementCard from './components/StatementCard';

function BenchmarkStatement() {
  const { id } = useParams<{ id: string }>();

  const { t } = useTranslate();

  const { data: statement, isLoading } = useGetStatementQuery(id ?? '');

  const [selectedTab, setSelectedTab] = useState<StatementTabType | undefined>(undefined);

  if (!statement) {
    if (isLoading) {
      return null;
    }

    return <NotFound />;
  }

  return (
    <>
      <StatementHeader statement={statement} selectedTab={selectedTab} onTabSelected={tab => setSelectedTab(tab)} />
      <Container sx={{ paddingY: 4 }}>
        {selectedTab === 'layout' && <LayoutCard statement={statement} />}
        {selectedTab === 'statement' && <StatementCard statement={statement} />}
      </Container>
    </>
  );
}

export default BenchmarkStatement;
