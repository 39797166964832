import { Alert, Stack } from '@mui/material';
import useNetwork from '../../../../hooks/useNetwork';
import useTranslate from '../../../../hooks/useTranslate';
import { useAppDispatch, useAppSelector } from '../../../../store';
import useSaveStatement from '../../../statement/create/hooks/useSaveStatement';
import { removeReport, removeStatement } from '../../../../store/slices/offline.slice';
import useSaveReport from '../../../intervention-report/create/hooks/useSaveReport';

function OfflineCard() {
  const { t, localeDate } = useTranslate();
  const { online } = useNetwork();

  const dispatch = useAppDispatch();
  const { lastSyncAt, statements, reports } = useAppSelector(state => state.offline);

  const saveStatement = useSaveStatement();
  const saveReport = useSaveReport();

  const statementsCount = statements.length;
  const reportsCount = reports.length;

  async function handleSyncStatements() {
    let numberOfErrors = 0;
    for (const statement of statements) {
      try {
        await saveStatement(statement);
        dispatch(removeStatement(statement.tempId));
      } catch (e) {
        numberOfErrors++;
      }
    }

    if (numberOfErrors > 0) {
      alert(t('statement.error.sync_failed', { count: numberOfErrors }));
    }

    window.location.reload();
  }

  async function handleSyncReports() {
    let numberOfErrors = 0;
    for (const report of reports) {
      try {
        await saveReport(report);
        dispatch(removeReport(report.tempId));
      } catch (e) {
        numberOfErrors++;
      }
    }

    if (numberOfErrors > 0) {
      alert(t('report.error.sync_failed', { count: numberOfErrors }));
    }

    window.location.reload();
  }

  return (
    <Stack direction="column" spacing={2}>
      {!online && (
        <Alert severity="warning">
          {t('global.offline_mode', { date: localeDate(new Date(lastSyncAt), 'long', true) })}
        </Alert>
      )}

      {statementsCount !== 0 && (
        <Alert
          severity="info"
          onClick={online ? handleSyncStatements : undefined}
          sx={{ cursor: online ? 'pointer' : 'default' }}>
          {online
            ? t('dashboard.click_to_synchronize_statements', { count: statementsCount })
            : t('dashboard.number_of_offline_statements', { count: statementsCount })}
        </Alert>
      )}

      {reportsCount !== 0 && (
        <Alert
          severity="info"
          onClick={online ? handleSyncReports : undefined}
          sx={{ cursor: online ? 'pointer' : 'default' }}>
          {online
            ? t('dashboard.click_to_synchronize_reports', { count: reportsCount })
            : t('dashboard.number_of_offline_reports', { count: reportsCount })}
        </Alert>
      )}
    </Stack>
  );
}

export default OfflineCard;
