import { useEffect, useState } from 'react';
import { ThemeProvider } from '@mui/material';
import { Provider } from 'react-redux';
import { persistor, store } from './store';
import AuthInitializer from './components/initializers/AuthInitializer';
import themeOptions from './theme';
import AppRouter from './routes/AppRouter';
import { PersistGate } from 'redux-persist/integration/react';
import DataLoader from './components/initializers/DataLoader';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

function App() {
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    serviceWorkerRegistration.register();
  }, []);

  return (
    <ThemeProvider theme={themeOptions}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <AuthInitializer onInitialized={() => setIsInitialized(true)} />
          {isInitialized && (
            <>
              <DataLoader />
              <AppRouter />
            </>
          )}
        </PersistGate>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
