import React from 'react';
import { Alert, Button, IconButton, ImageList, ImageListItem, Stack } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ClearIcon from '@mui/icons-material/Clear';
import { FileBase64 } from '../../../helpers/photos';

/** Props for the {@link PhotoInput} component. */
export interface PhotoInputProps {
  /** The label to display on the button. */
  label: string;
  /** The message to display above the button. */
  infoMessage?: string;
  /** The photo blobs to display. */
  photos?: FileBase64[];
  /** A callback for when a photo is selected. */
  onSelectPhoto?: (photo: FileBase64) => void;
  /** A callback for when a photo remove button is clicked. */
  onRemovePhoto?: (index: number) => void;
}

/** An input for uploading photos. */
function PhotoInput({ label, infoMessage, photos = [], onSelectPhoto, onRemovePhoto }: PhotoInputProps) {
  async function handleAddPhotos(evt: React.ChangeEvent<HTMLInputElement>) {
    if (!evt.target.files) return;

    const files = Array.from(evt.target.files);
    for (const file of files) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64Image = reader.result;
        if (typeof base64Image !== 'string') return;
        onSelectPhoto?.({
          name: file.name,
          type: file.type,
          size: file.size,
          base64: base64Image,
        });
      };
      reader.readAsDataURL(file);
    }
  }

  return (
    <Stack direction="column" spacing={2}>
      {infoMessage && <Alert severity="warning">{infoMessage}</Alert>}
      {photos.length > 0 && (
        <ImageList cols={3} gap={4}>
          {photos.map((photo, index) => (
            <ImageListItem key={index} sx={{ position: 'relative' }}>
              {typeof onRemovePhoto === 'function' && (
                <IconButton
                  aria-label="delete"
                  size="small"
                  sx={{ position: 'absolute', top: 4, right: 4, zIndex: 1 }}
                  onClick={() => onRemovePhoto!(index)}>
                  <ClearIcon fontSize="small" sx={{ color: 'white' }} />
                </IconButton>
              )}
              <img src={photo.base64} loading="lazy" alt={photo.name} />
            </ImageListItem>
          ))}
        </ImageList>
      )}
      <Button component="label" variant="contained" startIcon={<CloudUploadIcon />} size="small" sx={{ py: 1 }}>
        {label}
        <input accept="image/*" type="file" capture="environment" hidden multiple onChange={handleAddPhotos} />
      </Button>
    </Stack>
  );
}

export default PhotoInput;
