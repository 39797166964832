import { TableCell, TableRow } from '@mui/material';
import { ProductStock } from '../../../services/product.service';
import { getDepartmentNameFromZip } from '../../../helpers/departments';

export interface ProductStockRowProps {
  productStock: ProductStock;
}

function ProductStockRow({ productStock }: ProductStockRowProps) {
  return (
    <TableRow key={productStock.wholesaler.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <TableCell component="th" scope="row">
        {productStock.wholesaler.id}
      </TableCell>
      <TableCell component="th" scope="row">
        {productStock.wholesaler.name}
      </TableCell>
      <TableCell>
        {productStock.wholesaler.city} - {productStock.wholesaler.zip}
      </TableCell>
      <TableCell>{getDepartmentNameFromZip(productStock.wholesaler.zip)}</TableCell>
      <TableCell>{productStock.wholesaler.address1}</TableCell>
    </TableRow>
  );
}

export default ProductStockRow;
