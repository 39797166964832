import { useEffect, useState } from "react";
import Year from "../../../../utils/year";
import { BenchmarkProposalStatus, BenchmarkProposalSummary, useGetBenchmarkProposalsQuery } from "../../../../services/benchmark-proposal.service";
import { InterventionReportSummaryWithSales, useCountReportsQuery, useGetAllOrphansReportsQuery, useGetNumberOfImplantedMetersQuery } from "../../../../services/intervention-report.service";
import { getMinAndMaxDate } from "../../../../helpers/benchmark-proposal";
import { UserRole, useGetMySubordinatesQuery } from "../../../../services/user.service";
import { useGetGroupsQuery } from "../../../../services/wholesaler.service";
import { getSubordinatesByRole } from "../../../../helpers/user";

export type ActivityMonotoringRow = {
  type: 'proposal';
  data: BenchmarkProposalSummary;
} | {
  type: 'report';
  data: InterventionReportSummaryWithSales;
};

/** A hook to help with the activity monotoring section of the dashboard. */
function useActivityMonitoring() {
  const [availableYears, setAvailableYears] = useState<number[]>([]);

  const [page, setPage] = useState<number>(1);

  const [query, setQuery] = useState<string>('');
  const [year, setYear] = useState<number | 'all'>('all');
  const [month, setMonth] = useState<number | 'all'>('all');
  const [userId, setUserId] = useState<number | 'all'>('all');
  const [state, setState] = useState<BenchmarkProposalStatus | 'all'>('all');
  const [group, setGroup] = useState<string | 'all'>('all');
  const [entity, setEntity] = useState<string | 'all'>('all');

  const [rows, setRows] = useState<ActivityMonotoringRow[]>([]);

  const { data: benchmarkProposals, isFetching: areBenchmarkProposalsLoading } = useGetBenchmarkProposalsQuery({
    page,
    filters: {
      wholesalerQuery: query.trim() || undefined,
      year: year === 'all' ? undefined : year,
      month: month === 'all' ? undefined : month,
      userId: userId === 'all' ? undefined : userId,
      state: state === 'all' ? undefined : state,
      group: group === 'all' ? undefined : group,
      entity: entity === 'all' ? undefined : entity,
    }
  });
  const { data: orphansReports, isFetching: areOrphansReportsLoading } = useGetAllOrphansReportsQuery({
    userId: userId === 'all' ? undefined : userId,
    group: group === 'all' ? undefined : group,
    entity: entity === 'all' ? undefined : entity,
    wholesalerQuery: query.trim() || undefined,
  });
  const { data: numberOfImplantedMeters } = useGetNumberOfImplantedMetersQuery({
    year: year === 'all' ? undefined : year,
    month: month === 'all' ? undefined : month,
    userId: userId === 'all' ? undefined : userId,
    state: state === 'all' ? undefined : state,
    group: group === 'all' ? undefined : group,
    entity: entity === 'all' ? undefined : entity,
    wholesalerQuery: query.trim() || undefined,
  });
  const { data: reportsCount } = useCountReportsQuery({
    year: year === 'all' ? undefined : year,
    month: month === 'all' ? undefined : month,
    userId: userId === 'all' ? undefined : userId,
    state: state === 'all' ? undefined : state,
    group: group === 'all' ? undefined : group,
    entity: entity === 'all' ? undefined : entity,
    wholesalerQuery: query.trim() || undefined,
  });
  const { data: subordinates } = useGetMySubordinatesQuery();
  const { data: groups } = useGetGroupsQuery();

  useEffect(() => {
    const newRows: ActivityMonotoringRow[] = [];
    if (benchmarkProposals) {
      newRows.push(...benchmarkProposals.edges.map(proposal => ({ type: 'proposal', data: proposal }) as ActivityMonotoringRow));
    }
    if (orphansReports) {
      const { minDate, maxDate } = getMinAndMaxDate(benchmarkProposals?.edges ?? []);
      if (benchmarkProposals?.pageInfo?.startCursor === 0) {
        maxDate.setFullYear(maxDate.getFullYear() + 50);
      }
      if (benchmarkProposals?.pageInfo?.hasNextPage === false) {
        minDate.setFullYear(minDate.getFullYear() - 50);
      }

      const relevantOrphansReports = orphansReports.filter(report => {
        if (state !== 'all' && state !== BenchmarkProposalStatus.DONE) {
          return false;
        }
        if (year !== 'all' && new Date(report.createdAt).getFullYear() !== year) {
          return false;
        }
        if (month !== 'all' && new Date(report.createdAt).getMonth() + 1 !== month) {
          return false;
        }
        return new Date(report.createdAt) >= minDate && new Date(report.createdAt) <= maxDate;
      });

      newRows.push(...relevantOrphansReports.map(report => ({ type: 'report', data: report }) as ActivityMonotoringRow));
    }

    newRows.sort((a, b) => {
      const dateA = new Date(a.data.createdAt);
      const dateB = new Date(b.data.createdAt);
      return dateB.getTime() - dateA.getTime();
    });

    setRows(newRows);
  }, [year, month, state, benchmarkProposals, orphansReports]);

  useEffect(() => {
    if (year === 'all') {
      setMonth('all');
    }
  }, [year]);

  // Generate the available years.
  useEffect(() => {
    const currentYear = Year.current();
    setAvailableYears(currentYear.getLastYears(10));
  }, []);

  return {
    query,
    year,
    month,
    userId,
    state,
    group,
    entity,
    availableYears,
    rows,
    loading: areBenchmarkProposalsLoading || areOrphansReportsLoading,
    pageInfo: benchmarkProposals?.pageInfo,
    numberOfImplantedMeters: numberOfImplantedMeters ?? { total: 0, detail: {} },
    reportsCount: reportsCount ?? 0,
    sales: subordinates ?? [],
    salesCoordinators: getSubordinatesByRole(UserRole.SALES_COORDINATOR, subordinates ?? []),
    salesManagers: getSubordinatesByRole(UserRole.SALES_MANAGER, subordinates ?? []),
    groups: groups?.map(g => g.name) ?? [],
    entities: groups?.find(g => g.name === group)?.entities ?? [],
    setPage,
    setQuery,
    setYear,
    setMonth,
    setUserId,
    setState,
    setGroup,
    setEntity,
  };
}

export default useActivityMonitoring;
