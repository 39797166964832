import { Paper, Stack, Typography } from '@mui/material';
import useTranslate from '../../../../hooks/useTranslate';

interface LinearMetersPaperProps {
  total: number;
  detail?: { label: string; value: number }[];
}

function LinearMetersPaper({ total, detail = [] }: LinearMetersPaperProps) {
  const { t, localeNumber } = useTranslate();

  return (
    <Paper variant="outlined" sx={{ padding: 2, marginBottom: 2, flexGrow: 1 }}>
      <Typography variant="subtitle1" color="text.primary">
        {t('dashboard.number_of_implanted_meters')}
      </Typography>
      <Stack direction="column" spacing={0}>
        {detail.map(item => (
          <Stack key={item.label} direction="row" alignItems="center" justifyContent={'space-between'}>
            <Typography variant="body2" color="text.secondary">
              {item.label}
            </Typography>
            <Typography variant="h6" fontSize={18} color="text.primary">
              {localeNumber(item.value)}m
            </Typography>
          </Stack>
        ))}
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent={'space-between'}>
        <Typography variant="body1" color="text.secondary">
          {t('global.total')}
        </Typography>
        <Typography variant="h6" fontSize={22} color="text.primary">
          {localeNumber(total)}m
        </Typography>
      </Stack>
    </Paper>
  );
}

export default LinearMetersPaper;
