import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import Config from '../config';
import { prepareHeaders } from '../helpers/services';
import { Paginated } from './types';
import { User } from './user.service';
import { Product } from './product.service';
import { Wholesaler } from './wholesaler.service';
import BenchmarkProposal from '../pages/benchmark-proposal/show/BenchmarkProposal';
import { BenchmarkStatement } from './benchmark-statement.service';
import { InterventionReport, InterventionReportSummary } from './intervention-report.service';

/** Benchmark proposal status. */
export enum BenchmarkProposalStatus {
  /** The proposal is still under negotiation with the wholesaler. */
  TO_NEGOCIATE = 'to_negociate',
  /** The proposal has been accepted by the wholesaler. */
  ACCEPTED_BY_WHOLESALER = 'accepted_by_wholesaler',
  /** The proposal has been accepted by the sales manager. */
  ACCEPTED_BY_SALES_MANAGER = 'accepted_by_sales_manager',
  /** The sales manager asked for a revision of the proposal. */
  TO_BE_REVISED = 'to_be_revised',
  /** The proposal has been installed by the coordinator. */
  DONE = 'done',
}

/** The status of a product adjustment of a benchmark proposal. */
export enum ProductAdjustmentStatus {
  /** The product stock should be kept as is. */
  KEEP = 'keep',
  /** The product should be restocked in the wholesaler's store. */
  RESTOCK = 'restock',
  /** The product is missing from the wholesaler's store. */
  MISSING = 'missing',
  /** The product is not supposed to be in the wholesaler's store. */
  OUT_OF_SALES_PLAN = 'out_of_sales_plan',
  /** The product should be removed from the wholesaler's store. */
  REMOVE = 'remove',
  /** For old proposals */
  UNKNOWN = 'unknown',
}

export interface BenchmarkProposalProductAdjustment {
  id: number;
  benchmarkProposalId: number;
  product: Product;
  quantityAdjustment: number;
  status: ProductAdjustmentStatus;
}

export interface BenchmarkProposalSummary {
  id: number;
  sales: User;
  wholesaler: Wholesaler;
  salesComments?: string | null;
  salesManagerComments?: string | null;
  scheduledInstallationDate?: string | null;
  expectedCoordinator?: User;
  state: BenchmarkProposalStatus;
  interventionReport: InterventionReportSummary | null;
  updatedAt: string;
  createdAt: string;
}

export interface BenchmarkProposal {
  id: number;
  sales: User;
  benchmarkStatement: BenchmarkStatement;
  wholesaler: Wholesaler;
  salesComments?: string | null;
  salesManagerComments?: string | null;
  scheduledInstallationDate?: string | null;
  expectedCoordinator?: User;
  state: BenchmarkProposalStatus;
  productAdjustments: BenchmarkProposalProductAdjustment[];
  interventionReport: InterventionReport | null;
  updatedAt: string;
  createdAt: string;
}

export interface GetBenchmarkProposalsFilters {
  year?: number;
  month?: number;
  userId?: number;
  state?: BenchmarkProposalStatus;
  group?: string;
  entity?: string;
  wholesalerQuery?: string;
}

/** An API for benchmark proposals. */
export const benchmarkProposalApi = createApi({
  reducerPath: 'benchmarkProposalApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${Config.apiBaseUrl}/benchmark-proposal`, prepareHeaders }),
  tagTypes: ['BenchmarkProposals'],
  endpoints: (builder) => ({
    /**
     * Get a benchmark proposal by id.
     * @param id The benchmark proposal's id.
     */
    getBenchmarkProposal: builder.query<BenchmarkProposal, number | string>({
      query: (id) => `/${id}`,
    }),

    /**
     * Get a list of benchmark proposals.
     * @param page The page number to fetch.
     */
    getBenchmarkProposals: builder.query<Paginated<BenchmarkProposalSummary>, { page?: number, filters?: GetBenchmarkProposalsFilters } | void>({
      query: (params) => {
        const searchParams = new URLSearchParams();
        if (params?.page) searchParams.append('page', params.page.toString());
        if (params?.filters?.year) searchParams.append('year', params.filters.year.toString());
        if (params?.filters?.month) searchParams.append('month', (params.filters.month - 1).toString());
        if (params?.filters?.userId) searchParams.append('userId', params.filters.userId.toString());
        if (params?.filters?.state) searchParams.append('state', params.filters.state);
        if (params?.filters?.group) searchParams.append('group', params.filters.group);
        if (params?.filters?.entity) searchParams.append('entity', params.filters.entity);
        if (params?.filters?.wholesalerQuery) searchParams.append('wholesalerQuery', params.filters.wholesalerQuery);
        return `?${searchParams.toString()}`;
      },
      providesTags: (result) =>
        result
          ? [
            ...result.edges.map(({ id }) => ({ type: 'BenchmarkProposals' as const, id })),
            { type: 'BenchmarkProposals', id: 'PARTIAL-LIST' },
          ]
          : [{ type: 'BenchmarkProposals', id: 'PARTIAL-LIST' }],
    }),

    /**
     * Get a list of benchmark proposals accepted by the sales manager. Only for sales coordinators.
     */
    getProposalsAcceptedBySalesManager: builder.query<BenchmarkProposal[], void>({
      query: () => 'accepted-by-sales-manager',
    }),

    /**
     * Creates a new adjustment for a product in a benchmark proposal.
     * @param benchmarkProposalId The id of the benchmark proposal.
     * @param productRef The reference of the product to adjust.
     * @param quantity The new quantity of the product adjustment.
     */
    createBenchmarkProposalAdjustment: builder.mutation<BenchmarkProposalProductAdjustment, { benchmarkProposalId: number, productRef: string, quantity: number }>({
      query: ({ benchmarkProposalId, productRef, quantity }) => ({
        url: `/${benchmarkProposalId}/adjustment`,
        method: 'POST',
        body: {
          productRef,
          quantity,
        }
      }),
      invalidatesTags: [{ type: 'BenchmarkProposals', id: 'PARTIAL-LIST' }],
    }),

    /**
     * Updates an adjustment for a product in a benchmark proposal.
     * @param benchmarkProposalId The id of the benchmark proposal.
     * @param adjustmentId The id of the adjustment to update.
     * @param quantity The new quantity of the product adjustment.
     */
    updateBenchmarkProposalAdjustment: builder.mutation<BenchmarkProposalProductAdjustment, { benchmarkProposalId: number, adjustmentId: number, quantity: number }>({
      query: ({ benchmarkProposalId, adjustmentId, quantity }) => ({
        url: `/${benchmarkProposalId}/adjustment/${adjustmentId}`,
        method: 'PUT',
        body: {
          quantity,
        }
      }),
      invalidatesTags: [{ type: 'BenchmarkProposals', id: 'PARTIAL-LIST' }],
    }),

    /**
     * Deletes an adjustment for a product in a benchmark proposal.
     * @param benchmarkProposalId The id of the benchmark proposal.
     * @param adjustmentId The id of the adjustment to delete.
     */
    deleteBenchmarkProposalAdjustment: builder.mutation<BenchmarkProposalProductAdjustment, { benchmarkProposalId: number, adjustmentId: number }>({
      query: ({ benchmarkProposalId, adjustmentId }) => ({
        url: `/${benchmarkProposalId}/adjustment/${adjustmentId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'BenchmarkProposals', id: 'PARTIAL-LIST' }],
    }),

    /**
     * Updates the scheduled installation date of a benchmark proposal.
     * @param benchmarkProposalId The id of the benchmark proposal.
     * @param date The new date of the scheduled installation.
     */
    updateScheduledInstallationDate: builder.mutation<BenchmarkProposal, { benchmarkProposalId: number, date: string }>({
      query: ({ benchmarkProposalId, date }) => ({
        url: `/${benchmarkProposalId}/scheduled-installation-date`,
        method: 'PUT',
        body: {
          date,
        }
      }),
      invalidatesTags: [{ type: 'BenchmarkProposals', id: 'PARTIAL-LIST' }],
    }),

    /**
     * Ask a review for a benchmark proposal. It will set it's state to `to_be_revised`.
     * @param benchmarkProposalId The id of the benchmark proposal.
     * @param salesManagerComments Required comments from the sales manager about the review.
     */
    askBenchmarkProposalReview: builder.mutation<BenchmarkProposal, { benchmarkProposalId: number, salesManagerComments: string }>({
      query: ({ benchmarkProposalId, salesManagerComments }) => ({
        url: `/${benchmarkProposalId}/ask-review`,
        method: 'PUT',
        body: {
          salesManagerComments,
        }
      }),
      invalidatesTags: [{ type: 'BenchmarkProposals', id: 'PARTIAL-LIST' }],
    }),

    /**
     * Accept a benchmark proposal. It will set it's state to `accepted_by_sales_manager`.
     * @param benchmarkProposalId The id of the benchmark proposal.
     */
    acceptBenchmarkProposal: builder.mutation<BenchmarkProposal, number>({
      query: (id) => ({
        url: `/${id}/accept`,
        method: 'PUT',
      }),
      invalidatesTags: [{ type: 'BenchmarkProposals', id: 'PARTIAL-LIST' }],
    }),

    /**
     * Submits a benchmark proposal. It will set it's state to `accepted`.
     * @param benchmarkProposalId The id of the benchmark proposal.
     * @param salesComments Optional comments from the sales about the proposal.
     */
    submitBenchmarkProposal: builder.mutation<BenchmarkProposal, { benchmarkProposalId: number, salesComments?: string }>({
      query: ({ benchmarkProposalId, salesComments }) => ({
        url: `/${benchmarkProposalId}/submit`,
        method: 'PUT',
        body: {
          salesComments,
        }
      }),
      invalidatesTags: [{ type: 'BenchmarkProposals', id: 'PARTIAL-LIST' }],
    }),
  }),
});

export const {
  useGetBenchmarkProposalQuery,
  useGetBenchmarkProposalsQuery,
  useLazyGetBenchmarkProposalQuery,
  useLazyGetBenchmarkProposalsQuery,
  useLazyGetProposalsAcceptedBySalesManagerQuery,
  useCreateBenchmarkProposalAdjustmentMutation,
  useUpdateBenchmarkProposalAdjustmentMutation,
  useDeleteBenchmarkProposalAdjustmentMutation,
  useUpdateScheduledInstallationDateMutation,
  useAskBenchmarkProposalReviewMutation,
  useAcceptBenchmarkProposalMutation,
  useSubmitBenchmarkProposalMutation,
} = benchmarkProposalApi;