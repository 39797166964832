import React from 'react';
import {
  Autocomplete,
  Box,
  Card,
  FormControl,
  Hidden,
  InputLabel,
  ListSubheader,
  MenuItem,
  Pagination,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import useTranslate from '../../../../hooks/useTranslate';
import useActivityMonitoring from '../hooks/useActivityMonitoring';
import { BenchmarkProposalStatus } from '../../../../services/benchmark-proposal.service';
import { UserRole } from '../../../../services/user.service';
import { useAppSelector } from '../../../../store';
import BenchmarkProposalRow from './BenchmarkProposalRow';
import InterventionReportRow from './InterventionReportRow';
import { getFullName } from '../../../../helpers/user';
import useNetwork from '../../../../hooks/useNetwork';
import LinearMetersPaper from './LinearMetersPaper';
import SearchInput from '../../../../components/ui/form/SearchInput';

export interface ActivityMonitoringCardProps {
  showTitle?: boolean;
}

function ActivityMonitoringCard({ showTitle = true }: ActivityMonitoringCardProps) {
  const { online } = useNetwork();

  const { user } = useAppSelector(state => state.auth);

  const { t, localeNumber } = useTranslate();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const {
    query,
    year,
    month,
    userId,
    state,
    group,
    entity,
    rows,
    loading,
    pageInfo,
    numberOfImplantedMeters,
    reportsCount,
    sales,
    salesCoordinators,
    salesManagers,
    groups,
    entities,
    setPage,
    setQuery,
    setYear,
    setMonth,
    setUserId,
    setState,
    setGroup,
    setEntity,
    availableYears,
  } = useActivityMonitoring();

  const isSales = user?.role === UserRole.SALES;
  const isDirector = user?.role === UserRole.SALES_DIRECTOR;
  const isManagerOrDirector = user?.role === UserRole.SALES_MANAGER || user?.role === UserRole.SALES_DIRECTOR;

  if (!online) {
    return null;
  }

  return (
    <Card variant="outlined">
      {showTitle && (
        <Typography variant="h6" component="h2" fontWeight="regular" mb={2}>
          {t('dashboard.activity_monitoring')}
        </Typography>
      )}

      <Stack direction="column" spacing={2} mb={2}>
        <SearchInput
          placeholder={t('global.search')}
          value={query}
          onChange={evt => setQuery(evt.target.value)}
          size="small"
          fullWidth
        />
        <Stack direction={isTablet ? 'column' : 'row'} spacing={2} my={2}>
          <FormControl size="small" sx={{ minWidth: 100 }} fullWidth>
            <InputLabel id="year-select-label">{t('global.year')}</InputLabel>
            <Select
              labelId="year-select-label"
              id="year-select"
              value={year}
              label={t('global.year')}
              onChange={evt => setYear(evt.target.value === 'all' ? 'all' : Number(evt.target.value))}>
              <MenuItem value="all">{t('global.all_female')}</MenuItem>
              {availableYears.map(year => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl size="small" sx={{ minWidth: 100 }} fullWidth>
            <InputLabel id="month-select-label">{t('global.month')}</InputLabel>
            <Select
              labelId="month-select-label"
              id="month-select"
              value={month.toString()}
              label={t('global.month')}
              onChange={evt => setMonth(evt.target.value === 'all' ? 'all' : Number(evt.target.value))}
              disabled={year === 'all'}>
              <MenuItem value="all">{t('global.all_male')}</MenuItem>
              <MenuItem value={1}>{t('global.month.january')}</MenuItem>
              <MenuItem value={2}>{t('global.month.february')}</MenuItem>
              <MenuItem value={3}>{t('global.month.march')}</MenuItem>
              <MenuItem value={4}>{t('global.month.april')}</MenuItem>
              <MenuItem value={5}>{t('global.month.may')}</MenuItem>
              <MenuItem value={6}>{t('global.month.june')}</MenuItem>
              <MenuItem value={7}>{t('global.month.july')}</MenuItem>
              <MenuItem value={8}>{t('global.month.august')}</MenuItem>
              <MenuItem value={9}>{t('global.month.september')}</MenuItem>
              <MenuItem value={10}>{t('global.month.october')}</MenuItem>
              <MenuItem value={11}>{t('global.month.november')}</MenuItem>
              <MenuItem value={12}>{t('global.month.december')}</MenuItem>
            </Select>
          </FormControl>
          <FormControl size="small" sx={{ minWidth: 100 }} fullWidth>
            <InputLabel id="state-select-label">{t('global.status')}</InputLabel>
            <Select
              labelId="state-select-label"
              id="state-select"
              value={state ?? ''}
              label={t('global.status')}
              onChange={evt =>
                setState(evt.target.value === 'all' ? 'all' : (evt.target.value as BenchmarkProposalStatus))
              }>
              <MenuItem value="all">{t('global.all_male')}</MenuItem>
              {(user?.role === UserRole.SALES || user?.role === UserRole.SALES_DIRECTOR) && (
                <MenuItem value="to_negociate">{t('benchmark_proposal.status.to_negociate')}</MenuItem>
              )}
              {user?.role !== UserRole.SALES_COORDINATOR && (
                <MenuItem value="accepted_by_wholesaler">
                  {t('benchmark_proposal.status.accepted_by_wholesaler')}
                </MenuItem>
              )}
              <MenuItem value="accepted_by_sales_manager">
                {t('benchmark_proposal.status.accepted_by_sales_manager')}
              </MenuItem>
              {user?.role !== UserRole.SALES_COORDINATOR && (
                <MenuItem value="to_be_revised">{t('benchmark_proposal.status.to_be_revised')}</MenuItem>
              )}
              <MenuItem value="done">{t('benchmark_proposal.status.done')}</MenuItem>
            </Select>
          </FormControl>
        </Stack>
        <Stack direction={isTablet ? 'column' : 'row'} spacing={2} my={2}>
          {/* User filter for all roles except sales */}
          {!isSales && (
            <FormControl size="small" sx={{ minWidth: 200 }} fullWidth>
              <InputLabel id="user-select-label">{t('user.title')}</InputLabel>
              <Select
                labelId="user-select-label"
                id="sales-select"
                value={userId}
                label={t('user.title')}
                onChange={evt => setUserId(evt.target.value as number | 'all')}>
                <MenuItem value="all">{t('global.all_male')}</MenuItem>
                {/* Directors can filter on managers */}
                {isDirector && salesManagers.length > 0 && (
                  <ListSubheader>{t('user.role.sales_manager_plurial')}</ListSubheader>
                )}
                {isDirector &&
                  salesManagers.map(sale => (
                    <MenuItem key={sale.id} value={sale.id}>
                      {getFullName(sale)}
                    </MenuItem>
                  ))}

                {/* Directors and managers can filter on coordinators */}
                {isManagerOrDirector && salesCoordinators.length > 0 && (
                  <ListSubheader>{t('user.role.sales_coordinator_plurial')}</ListSubheader>
                )}
                {isManagerOrDirector &&
                  salesCoordinators.map(sale => (
                    <MenuItem key={sale.id} value={sale.id}>
                      {getFullName(sale)}
                    </MenuItem>
                  ))}

                {/* Everyone can filter on sales */}
                {sales.length > 0 && <ListSubheader>{t('user.role.sales_plurial')}</ListSubheader>}
                {sales.map(sale => (
                  <MenuItem key={sale.id} value={sale.id}>
                    {getFullName(sale)} - {sale.code}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <Autocomplete
            size="small"
            options={[t('global.all_male'), ...groups]}
            value={group === 'all' ? t('global.all_male') : group}
            onChange={(_, value) => {
              if (value === null || value === t('global.all_male')) {
                setGroup('all');
              } else setGroup(value);
              setEntity('all');
            }}
            renderInput={params => <TextField {...params} label={t('wholesaler.group')} />}
            sx={{ minWidth: 100 }}
            disablePortal
            fullWidth
          />
          <Autocomplete
            size="small"
            options={[t('global.all_female'), ...entities]}
            value={entity === 'all' ? t('global.all_female') : entity}
            onChange={(_, value) => {
              if (value === null || value === t('global.all_female')) {
                setEntity('all');
              } else setEntity(value);
            }}
            renderInput={params => <TextField {...params} label={t('wholesaler.entity')} />}
            sx={{ minWidth: 100 }}
            disablePortal
            fullWidth
            disabled={group === 'all'}
          />
        </Stack>
      </Stack>

      <Stack direction={isMobile ? 'column' : 'row'} spacing={2} mb={2}>
        <Paper variant="outlined" sx={{ padding: 2, marginBottom: 2, flexGrow: 1 }}>
          <Typography variant="subtitle1" color="text.primary">
            {t('dashboard.number_of_reports')}
          </Typography>
          <Typography variant="h6" color="text.primary">
            {localeNumber(reportsCount)}
          </Typography>
        </Paper>

        <LinearMetersPaper
          total={numberOfImplantedMeters.total}
          detail={[
            { label: t('market.public_places'), value: numberOfImplantedMeters.detail.publicPlacesMeters ?? 0 },
            { label: t('market.renovation'), value: numberOfImplantedMeters.detail.renovationMeters ?? 0 },
            { label: t('market.access'), value: numberOfImplantedMeters.detail.accessMeters ?? 0 },
          ]}
        />
      </Stack>

      <TableContainer component={props => <Paper variant="outlined" {...props} />}>
        <Table
          aria-label="benchmark proposals table"
          sx={{ '& > tbody tr:nth-of-type(odd)': { backgroundColor: '#00000006' } }}>
          <TableHead>
            <TableRow>
              <TableCell>{t('benchmark_proposal.intial_statement')}</TableCell>
              <Hidden smDown>
                <TableCell>{t('benchmark_proposal.date')}</TableCell>
                <TableCell>{t('benchmark_proposal.sales')}</TableCell>
                <TableCell>{t('user.role.sales_coordinator')}</TableCell>
                <TableCell>{t('benchmark_proposal.name')}</TableCell>
                <TableCell>{t('benchmark_proposal.zip')}</TableCell>
                <TableCell>{t('benchmark_proposal.city')}</TableCell>
              </Hidden>
              <TableCell>{t('benchmark_proposal.status')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => (
              <React.Fragment key={`${row.type}_${row.data.id}`}>
                {row.type === 'proposal' && <BenchmarkProposalRow proposal={row.data} />}
                {row.type === 'report' && <InterventionReportRow report={row.data} />}
              </React.Fragment>
            ))}

            {rows.length === 0 && !loading && (
              <TableRow>
                <TableCell colSpan={8} align="center">
                  {t('benchmark_proposal.empty_for_filters')}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {(pageInfo?.numberOfPages ?? 0) > 1 && (
        <Box mt={2} display="flex" justifyContent="flex-end">
          <Pagination count={pageInfo?.numberOfPages} color="primary" onChange={(_, value) => setPage(value)} />
        </Box>
      )}
    </Card>
  );
}

export default ActivityMonitoringCard;
