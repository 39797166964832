import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import useTranslate from '../../../../hooks/useTranslate';
import { BenchmarkProposal, BenchmarkProposalProductAdjustment } from '../../../../services/benchmark-proposal.service';
import { nonBreaking } from '../../../../utils/string';
import ProposalAdjustmentRow from './ProposalAdjustmentRow';
import { useAppSelector } from '../../../../store';
import { UserRole } from '../../../../services/user.service';

export interface ProposalAdjustmentsTableProps {
  /** The benchmark proposal. */
  proposal: BenchmarkProposal;
  /** The adjustments of the proposal with statuses. */
  adjustments: BenchmarkProposalProductAdjustment[];
  /** Whether the form is disabled. */
  isFormDisabled?: boolean;
  /** Callback fired when the adjustment is deleted. */
  onAdjustmentDeleted?: () => void;
  /** Callback fired when the adjustment is updated. */
  onAdjustmentUpdated?: () => void;
  /** Callback fired when the adjustment is moved. */
  onAdjustmentMoved?: () => void;
}

function ProposalAdjustmentsTable({
  proposal,
  adjustments,
  isFormDisabled,
  onAdjustmentDeleted,
  onAdjustmentUpdated,
  onAdjustmentMoved,
}: ProposalAdjustmentsTableProps) {
  const { user } = useAppSelector(state => state.auth);

  const { t } = useTranslate();

  return (
    <TableContainer>
      <Table
        aria-label="product adjustments table"
        sx={{ '& > tbody tr:nth-of-type(odd)': { backgroundColor: '#00000006' } }}>
        <TableHead>
          <TableRow>
            <TableCell>{nonBreaking(t('product_adjustment.product_name'))}</TableCell>
            <TableCell>{nonBreaking(t('product_adjustment.status'))}</TableCell>
            <TableCell>{nonBreaking(t('product_adjustment.current_quantity'))}</TableCell>
            <TableCell>{nonBreaking(t('product_adjustment.adjustment'))}</TableCell>
            <TableCell>{nonBreaking(t('product_adjustment.minimum_quantity'))}</TableCell>
            {user?.role === UserRole.SALES && <TableCell>{nonBreaking(t('product_adjustment.actions'))}</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {adjustments.map(adjustment => (
            <ProposalAdjustmentRow
              key={adjustment.id}
              proposal={proposal}
              adjustment={adjustment}
              isFormDisabled={isFormDisabled}
              onAdjustmentDeleted={onAdjustmentDeleted}
              onAdjustmentUpdated={onAdjustmentUpdated}
              onAdjustmentMoved={onAdjustmentMoved}
            />
          ))}
          {adjustments.length === 0 && (
            <TableRow>
              <TableCell colSpan={6} align="center">
                {t('product.empty')}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default ProposalAdjustmentsTable;
