import { Button, Card, Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import StorefrontIcon from '@mui/icons-material/Storefront';
import useTranslate from '../../../../hooks/useTranslate';
import { useAppSelector } from '../../../../store';
import { UserRole } from '../../../../services/user.service';

function WholesalersCard() {
  const { t } = useTranslate();

  const { user } = useAppSelector(state => state.auth);

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    <Card variant="outlined">
      <Typography variant="h6" component="h2" fontWeight="regular">
        {t('dashboard.your_wholesalers')}
      </Typography>
      <Typography variant="caption" color="text.secondary" component="p" lineHeight="normal" mt={1} mb={2}>
        {user?.role === UserRole.SALES_MANAGER || user?.role === UserRole.SALES_DIRECTOR
          ? t('dashboard.your_wholesalers_description_manager')
          : t('dashboard.your_wholesalers_description')}
      </Typography>
      <Stack direction="column" spacing={1} mt={2} alignItems={'flex-start'}>
        <Button variant="contained" startIcon={<StorefrontIcon />} fullWidth={isMobile} href="/wholesaler">
          {t('dashboard.see_wholesalers')}
        </Button>
        <Button variant="contained" startIcon={<SearchIcon />} fullWidth={isMobile} href="/catalogue">
          {t('dashboard.search_reference')}
        </Button>
      </Stack>
    </Card>
  );
}

export default WholesalersCard;
