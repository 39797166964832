import { v4 as uuidv4 } from 'uuid';
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Wholesaler } from '../../services/wholesaler.service';
import { signOut } from './auth.slice';
import { WholesalerNetwork } from '../../services/wholesaler-network.service';
import { LayoutBenchmarkSummary } from '../../services/layout-benchmark.service';
import { FormSuggestionsSet } from '../../services/form-suggestion.service';
import { Product } from '../../services/product.service';
import { CreateStatementFormState } from './create-statement-form.slice';
import { BenchmarkProposal } from '../../services/benchmark-proposal.service';
import { CreateReportFormState } from './create-report-form.slice';

export interface OfflineRessource {
  /** The temporary ID of the resource. */
  tempId: string;
}

/** A statement created offline. */
export type OfflineStatement = CreateStatementFormState & OfflineRessource;

/** A report created offline. */
export type OfflineReport = CreateReportFormState & OfflineRessource;

/** Redux state for authentication. */
export interface OfflineState {
  /** The last time (as JS timestamp) when the data was synced. */
  lastSyncAt: number;
  /** The wholesalers of the user. */
  wholesalers: Wholesaler[];
  /** The wholesaler networks. */
  wholesalerNetworks: WholesalerNetwork[];
  /** The benchmark layouts. */
  layoutBenchmarks: LayoutBenchmarkSummary[];
  /** The products. */
  products: Product[];
  /** The form suggestions. */
  formSuggestions: FormSuggestionsSet;
  /** The benchmark proposals accepted by the sales manager. */
  proposalsAcceptedBySalesManager: BenchmarkProposal[];
  /** Statements created offline. */
  statements: OfflineStatement[];
  /** Reports created offline. */
  reports: OfflineReport[];
}

/** The initial state for the auth slice. */
export const initialState: OfflineState = {
  lastSyncAt: 0,
  wholesalers: [],
  wholesalerNetworks: [],
  layoutBenchmarks: [],
  products: [],
  formSuggestions: {
    contactPosition: [],
    linearHeight: [],
    linearWidth: [],
  },
  proposalsAcceptedBySalesManager: [],
  statements: [],
  reports: [],
}

export const offlineSlice = createSlice({
  name: 'offline',
  initialState,
  reducers: {
    /** Update the last sync date to the current time. */
    updateLastSyncDate: (state) => {
      state.lastSyncAt = new Date().getTime();
    },
    /** Persist the wholesalers. */
    saveWholesalers: (state, action: PayloadAction<Wholesaler[]>) => {
      state.wholesalers = action.payload;
    },
    /** Persist the wholesaler networks. */
    saveWholesalerNetworks: (state, action: PayloadAction<WholesalerNetwork[]>) => {
      state.wholesalerNetworks = action.payload;
    },
    /** Persist the benchmark layouts. */
    saveLayoutBenchmarks: (state, action: PayloadAction<LayoutBenchmarkSummary[]>) => {
      state.layoutBenchmarks = action.payload;
    },
    /** Persist the products. */
    saveProducts: (state, action: PayloadAction<Product[]>) => {
      state.products = action.payload;
    },
    /** Persist the form suggestions. */
    saveFormSuggestions: (state, action: PayloadAction<FormSuggestionsSet>) => {
      state.formSuggestions = action.payload;
    },
    /** Persist the benchmark proposals accepted by the sales manager. */
    saveProposalsAcceptedBySalesManager: (state, action: PayloadAction<BenchmarkProposal[]>) => {
      state.proposalsAcceptedBySalesManager = action.payload;
    },
    /** Add a statement to the offline store. */
    addStatement: (state, action: PayloadAction<CreateStatementFormState>) => {
      state.statements.push({
        ...action.payload,
        tempId: uuidv4(),
      });
    },
    /** Remove a statement from the offline store. */
    removeStatement: (state, action: PayloadAction<string>) => {
      state.statements = state.statements.filter(stmt => stmt.tempId !== action.payload);
    },
    /** Add a report to the offline store. */
    addReport: (state, action: PayloadAction<CreateReportFormState>) => {
      state.reports.push({
        ...action.payload,
        tempId: uuidv4(),
      });
    },
    /** Remove a report from the offline store. */
    removeReport: (state, action: PayloadAction<string>) => {
      state.reports = state.reports.filter(report => report.tempId !== action.payload);
    },
  },
  extraReducers: builder => {
    builder.addCase(signOut, () => initialState);
  },
});

export const {
  updateLastSyncDate,
  saveWholesalers,
  saveWholesalerNetworks,
  saveLayoutBenchmarks,
  saveProducts,
  saveFormSuggestions,
  saveProposalsAcceptedBySalesManager,
  addStatement,
  removeStatement,
  addReport,
  removeReport,
} = offlineSlice.actions;
export default offlineSlice.reducer;
