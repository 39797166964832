import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetWholesalerQuery } from '../../../services/wholesaler.service';
import {
  AppBar,
  Box,
  Button,
  Card,
  Container,
  Grid,
  Hidden,
  IconButton,
  Pagination,
  Paper,
  SpeedDial,
  SpeedDialAction,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import useTranslate from '../../../hooks/useTranslate';
import NotFound from '../../NoFound';
import { useDispatch } from 'react-redux';
import {
  resetCreateStatementFormState,
  setCreateStatementFormState,
} from '../../../store/slices/create-statement-form.slice';
import { getDepartmentNameFromZip } from '../../../helpers/departments';
import { nonBreaking } from '../../../utils/string';
import useWholesalerProposals from './hooks/useWholesalerProposals';
import WholesalerProposalRow from './components/WholesalerProposalRow';
import WholesalerReportRow from './components/WholesalerReportRow';
import { useAppSelector } from '../../../store';
import { UserRole } from '../../../services/user.service';
import themeConstants from '../../../theme/constants';

function Wholesaler() {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const { t } = useTranslate();
  const dispatch = useDispatch();
  const { user } = useAppSelector(state => state.auth);

  const { data, isLoading } = useGetWholesalerQuery(id ?? '');

  const { rows, page, pageInfo, loading: rowsLoading, setPage } = useWholesalerProposals(id ?? '');

  function handleNewStatementClick(handleNavigation: boolean = false) {
    dispatch(resetCreateStatementFormState());
    dispatch(setCreateStatementFormState({ wholesalerId: data!.id }));

    if (handleNavigation) {
      navigate('/statement/create?step=2');
    }
  }

  if (!data) {
    if (isLoading) {
      return null;
    }

    return <NotFound />;
  }

  const isArchived = data.deletedAt !== null;

  return (
    <>
      <AppBar position="fixed" color="transparent" sx={{ backgroundColor: 'white', marginTop: 'var(--navbar-height)' }}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="back"
            sx={{ mr: 1 }}
            onClick={() => navigate(-1)}>
            <ChevronLeftIcon />
          </IconButton>
          <Typography variant="subtitle1" color="inherit" component="div" sx={{ flexGrow: 1 }}>
            {data.name}
          </Typography>
          {!isArchived && (
            <Hidden smDown>
              <Stack direction="row" spacing={2}>
                {user?.role === UserRole.SALES && (
                  <Button
                    variant="contained"
                    startIcon={<AddIcon />}
                    size="small"
                    href="/statement/create?step=2"
                    onClick={() => handleNewStatementClick()}>
                    {t('statement.create')}
                  </Button>
                )}
              </Stack>
            </Hidden>
          )}
        </Toolbar>
      </AppBar>

      <Container sx={{ marginTop: `${themeConstants.navbarsHeight}px`, paddingY: 4 }}>
        {isArchived && (
          <Box p={2} borderRadius={1} color="white" sx={{ backgroundColor: 'error.main' }} mb={2}>
            <Typography variant="caption" color="inherit">
              {t('wholesaler.archived')}
            </Typography>
          </Box>
        )}
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <TableContainer component={props => <Paper variant="outlined" {...props} />}>
              <Table
                aria-label="wholesaler data table"
                sx={{ '& > tbody tr:nth-of-type(odd)': { backgroundColor: '#00000006' } }}>
                <TableHead>
                  <TableRow>
                    <TableCell>{t('global.name')}</TableCell>
                    <TableCell>{t('global.city')}</TableCell>
                    <TableCell>{t('global.department')}</TableCell>
                    <TableCell>{t('global.address')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      {data.name}
                    </TableCell>
                    <TableCell>{data.city}</TableCell>
                    <TableCell>{getDepartmentNameFromZip(data.zip)}</TableCell>
                    <TableCell>{data.address1}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid item xs={12}>
            <Card variant="outlined">
              <Typography variant="subtitle2" mb={1}>
                {t('wholesaler.group')}
              </Typography>
              <Typography variant="caption" color="text.secondary">
                {t('global.name')}
              </Typography>
              <Typography variant="body1">{data.group ?? '-'}</Typography>
              <Typography variant="caption" color="text.secondary">
                {t('wholesaler.entity')}
              </Typography>
              <Typography variant="body1">{data.entity ?? '-'}</Typography>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <TableContainer component={props => <Paper variant="outlined" {...props} />}>
              <Table
                aria-label="benchmark proposals table"
                sx={{ '& > tbody tr:nth-of-type(odd)': { backgroundColor: '#00000006' } }}>
                <TableHead>
                  <TableRow>
                    <TableCell>{t('benchmark_proposal.date')}</TableCell>
                    <TableCell>{t('benchmark_proposal.sales')}</TableCell>
                    <TableCell>{t('benchmark_proposal.status')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map(row => (
                    <React.Fragment key={`${row.type}_${row.data.id}`}>
                      {row.type === 'proposal' && <WholesalerProposalRow proposal={row.data} />}
                      {row.type === 'report' && <WholesalerReportRow report={row.data} />}
                    </React.Fragment>
                  ))}

                  {rows.length === 0 && !rowsLoading && (
                    <TableRow>
                      <TableCell colSpan={6} align="center">
                        {t('benchmark_proposal.empty')}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {(pageInfo?.numberOfPages ?? 0) > 1 && (
              <Box mt={2} display="flex" justifyContent="flex-end">
                <Pagination
                  page={page}
                  count={pageInfo?.numberOfPages}
                  color="primary"
                  onChange={(_, value) => setPage(value)}
                />
              </Box>
            )}
          </Grid>
        </Grid>
      </Container>

      {!isArchived && user?.role === UserRole.SALES && (
        <Hidden smUp>
          <SpeedDial
            ariaLabel="Wholesaler actions"
            sx={{ position: 'absolute', bottom: 16, right: 16 }}
            icon={<MoreVertIcon />}>
            <SpeedDialAction
              icon={<AddIcon />}
              tooltipTitle={nonBreaking(t('statement.create'))}
              tooltipOpen
              onClick={() => handleNewStatementClick(true)}
            />
          </SpeedDial>
        </Hidden>
      )}
    </>
  );
}

export default Wholesaler;
