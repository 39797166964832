import { fileBase64ToBlob } from '../../../../helpers/photos';
import {
  useCreateInterventionReportMutation,
  useLazyGetReportUploadPhotoUrlQuery,
} from '../../../../services/intervention-report.service';
import { CreateReportFormState } from '../../../../store/slices/create-report-form.slice';

function useSaveReport() {
  const [getReportUploadUrl] = useLazyGetReportUploadPhotoUrlQuery();
  const [createInterventionReport] = useCreateInterventionReportMutation();

  async function saveReport(createReportFormState: CreateReportFormState) {
    // Upload the photos to S3.
    const photoKeys: string[] = [];
    for (const photo of createReportFormState.photos) {
      const { data, error } = await getReportUploadUrl(photo.type);
      if (error || !data) {
        continue;
      }

      const { signedUrl, key } = data;
      photoKeys.push(key);

      await fetch(signedUrl, {
        method: 'PUT',
        headers: { 'Content-Type': 'image/*' },
        body: fileBase64ToBlob(photo),
      });
    }

    return createInterventionReport({
      benchmarkProposalId: createReportFormState.benchmarkProposalId ?? undefined,
      wholesalerId: createReportFormState.wholesalerId!,
      photoKeys: photoKeys,
      products: createReportFormState.productStatements.map(stmt => ({
        productRef: stmt.product.ref,
        quantity: stmt.quantity,
      })),
      isImplementation: createReportFormState.isImplementation,
      publicPlacesMeters: createReportFormState.publicPlacesMeters,
      renovationMeters: createReportFormState.renovationMeters,
      accessMeters: createReportFormState.accessMeters,
      isTraining: createReportFormState.isTraining,
      numberOfTrainedPeople: createReportFormState.numberOfTrainedPeople,
      isAnimation: createReportFormState.isAnimation,
      animationType: createReportFormState.animationType,
      isPrescription: createReportFormState.isPrescription,
      comments: createReportFormState.comments,
    }).unwrap();
  }

  return saveReport;
}

export default useSaveReport;
