import { useState } from 'react';
import useTranslate from '../../../hooks/useTranslate';
import { Box, Container, Grid, Tab, Typography } from '@mui/material';
import ActivityMonitoringCard from './components/ActivityMonitoringCard';
import WholesalersCard from './components/WholesalersCard';
import StatementsCard from './components/StatementsCard';
import { useAppSelector } from '../../../store';
import { UserRole } from '../../../services/user.service';
import InterventionReportsCard from './components/InterventionReportsCard';
import OfflineCard from './components/OfflineCard';
import PrivateStatementsCard from './components/PrivateStatementsCard';
import VisitReportsCard from './components/VisitReportsCard';
import { TabContext, TabList, TabPanel } from '@mui/lab';

/** The dashboard of a user. */
function Dashboard() {
  const { t } = useTranslate();

  const { user } = useAppSelector(state => state.auth);

  const [selectedTab, setSelectedTab] = useState<'implementations' | 'visits'>('implementations');

  const isManagerOrDirector = user?.role === UserRole.SALES_MANAGER || user?.role === UserRole.SALES_DIRECTOR;

  return (
    <Container sx={{ paddingY: 2 }}>
      <Typography variant="h5" component="h1">
        {t('dashboard.title')}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <OfflineCard />
        </Grid>
        {user?.role === UserRole.SALES && (
          <Grid item xs={12} md={6}>
            <StatementsCard />
          </Grid>
        )}
        {user?.role === UserRole.SALES_COORDINATOR && (
          <Grid item xs={12} md={6}>
            <InterventionReportsCard />
          </Grid>
        )}
        <Grid item xs={12} md={isManagerOrDirector ? 12 : 6}>
          <WholesalersCard />
        </Grid>
        {user?.role !== UserRole.SALES_COORDINATOR && user?.role !== UserRole.SALES_MANAGER ? (
          <>
            <Grid item xs={12}>
              <ActivityMonitoringCard />
            </Grid>
            {user?.role === UserRole.SALES && (
              <Grid item xs={12}>
                <PrivateStatementsCard />
              </Grid>
            )}
          </>
        ) : (
          <Grid item xs={12}>
            <Typography variant="h6" component="h2" fontWeight="regular" mt={2} mb={1}>
              {t('dashboard.activity_monitoring')}
            </Typography>
            <TabContext value={selectedTab}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={(_, newValue) => setSelectedTab(newValue)}>
                  <Tab label={t('dashboard.implementations')} value="implementations" />
                  <Tab
                    label={
                      user!.role == UserRole.SALES_COORDINATOR ? t('dashboard.my_visits') : t('dashboard.all_visits')
                    }
                    value="visits"
                  />
                </TabList>
              </Box>
              <TabPanel value="implementations" sx={{ paddingX: 0, paddingY: 2 }}>
                <ActivityMonitoringCard showTitle={false} />
              </TabPanel>
              <TabPanel value="visits" sx={{ paddingX: 0, paddingY: 2 }}>
                <VisitReportsCard />
              </TabPanel>
            </TabContext>
          </Grid>
        )}
      </Grid>
    </Container>
  );
}

export default Dashboard;
