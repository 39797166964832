import { useState } from 'react';
import useTranslate from '../../../../../hooks/useTranslate';
import { Wholesaler } from '../../../../../services/wholesaler.service';
import { Stack, Typography } from '@mui/material';
import WholesalerCard from '../../../../../components/wholesaler/WholesalerCard';
import SearchInput from '../../../../../components/ui/form/SearchInput';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { setCreateStatementFormState } from '../../../../../store/slices/create-statement-form.slice';
import { searchWholesalers } from '../../../../../helpers/wholesaler';

function CreateStatementStep1() {
  const { t } = useTranslate();

  const dispatch = useAppDispatch();
  const { wholesalerId } = useAppSelector(state => state.createStatementForm);
  const { wholesalers } = useAppSelector(state => state.offline);

  const [query, setQuery] = useState<string>('');

  const [filteredWholesalers, setFilteredWholesalers] = useState<Wholesaler[]>(wholesalers);

  function handleSearch(evt: React.ChangeEvent<HTMLInputElement>) {
    const newQuery = evt.currentTarget.value;
    setQuery(newQuery);
    setFilteredWholesalers(searchWholesalers(newQuery, wholesalers));
  }

  return (
    <Stack direction="column" spacing={2}>
      <SearchInput
        id="search-bar"
        placeholder={t('wholesaler.search')}
        size="small"
        value={query}
        onChange={handleSearch}
      />

      {filteredWholesalers.map(ws => (
        <WholesalerCard
          key={ws.id}
          wholesaler={ws}
          selected={ws.id === wholesalerId}
          onClick={() =>
            dispatch(
              setCreateStatementFormState({
                wholesalerId: ws.id,
              }),
            )
          }
        />
      ))}

      <Stack direction="column" alignItems="center" py={1}>
        <Typography variant="caption" component="div">
          {t('wholesaler.end_of_the_list')}
        </Typography>
      </Stack>
    </Stack>
  );
}

export default CreateStatementStep1;
