import { useState } from 'react';
import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import useTranslate from '../../../../hooks/useTranslate';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { resetCreateStatementFormState } from '../../../../store/slices/create-statement-form.slice';

function StatementsCard() {
  const { t } = useTranslate();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const dispatch = useAppDispatch();
  const { wholesalerId, updatedAt, step } = useAppSelector(state => state.createStatementForm);
  const [showExistingStatementDialog, setShowExistingStatementDialog] = useState(false);

  return (
    <>
      <Card variant="outlined" sx={{ height: '100%', boxSizing: 'border-box' }}>
        <Typography variant="h6" component="h2" fontWeight="regular">
          {t('dashboard.statement_creation')}
        </Typography>
        <Typography variant="caption" color="text.secondary" component="p" lineHeight="normal" mt={1} mb={2}>
          {t('dashboard.statement_creation_description')}
        </Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          fullWidth={isMobile}
          href="/statement/create?step=1"
          onClick={evt => {
            const updateDate = new Date(updatedAt);
            const oneDayAgo = new Date();
            oneDayAgo.setDate(oneDayAgo.getDate() - 1);
            if (wholesalerId && updateDate > oneDayAgo) {
              evt.preventDefault();
              setShowExistingStatementDialog(true);
            } else {
              dispatch(resetCreateStatementFormState());
            }
          }}>
          {t('dashboard.create_statement')}
        </Button>
      </Card>

      <Dialog
        open={showExistingStatementDialog}
        onClose={() => setShowExistingStatementDialog(false)}
        aria-labelledby="existing-statement-dialog-title"
        aria-describedby="existing-statement-dialog-description">
        <DialogTitle id="existing-statement-dialog-title">{t('statement.create.statement_in_progress')}</DialogTitle>
        <DialogContent>
          <DialogContentText id="existing-statement-dialog-description">
            {t('statement.create.statement_in_progress_description')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            onClick={() => dispatch(resetCreateStatementFormState())}
            href="/statement/create?step=1">
            {t('statement.create.new_statement')}
          </Button>
          <Button variant="contained" href={`/statement/create?step=${step}`} autoFocus>
            {t('statement.create.resume')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default StatementsCard;
