import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import Config from '../config';
import { prepareHeaders } from '../helpers/services';
import { User, UserSummary } from './user.service';
import { Wholesaler } from './wholesaler.service';
import { WholesalerNetwork, WholesalerSize } from './wholesaler-network.service';
import { LayoutBenchmark } from './layout-benchmark.service';
import { Product } from './product.service';
import { BenchmarkProposalSummary } from './benchmark-proposal.service';

export interface CreateProductStatementDto {
  productRef: string;
  quantity: number;
}

export interface CreateBenchmarkStatementDto {
  salesId: number;
  wholesalerId: string;
  wholesalerNetworkId: number;
  wholesalerSizeId: number;
  benchmarkLayoutId: number;
  contact: string;
  contactPosition: string;
  contactPhone: string;
  contactEmail?: string;
  trainingRequired: boolean;
  trainingComments?: string
  publicPlacesMeters: number;
  renovationMeters: number;
  accessMeters: number;
  tabletsAvailable: boolean;
  perforedBottomsInstalled: boolean;
  loadBarsAvailable?: boolean;
  linearHeight: string;
  linearWidth: string;
  photoKeys?: string[];
  products: CreateProductStatementDto[];
  comments?: string;
  isPrivate?: boolean;
}

export interface UpdateBenchmarkStatementContactDto {
  id: number;
  contact: string;
  contactPosition: string;
  contactPhone: string;
  contactEmail?: string;
}

export interface BenchmarkStatementPhoto {
  benchmarkStatementId: number;
  key: string;
}

export interface BenchmarkStatementProduct {
  benchmarkStatementId: number;
  product: Product;
  quantity: number;
}

export interface BenchmarkStatementSummary {
  id: number;
  sales: UserSummary;
  wholesaler: Wholesaler;
  wholesalerNetwork: WholesalerNetwork;
  wholesalerSize: WholesalerSize;
  contact: string;
  contactPosition: string;
  contactPhone: string;
  contactEmail: string;
  trainingRequired: boolean;
  trainingComments: string;
  publicPlacesMeters: number;
  renovationMeters: number;
  accessMeters: number;
  tabletsAvailable: boolean;
  perforedBottomsInstalled: boolean;
  loadBarsAvailable: boolean;
  linearHeight: string;
  linearWidth: string;
  comments: string;
  createdAt: string;
  updatedAt: string;
}

export interface BenchmarkStatement {
  id: number;
  sales: User;
  wholesaler: Wholesaler;
  wholesalerNetwork: WholesalerNetwork;
  wholesalerSize: WholesalerSize;
  benchmarkLayout: LayoutBenchmark;
  benchmarkProposal?: BenchmarkProposalSummary;
  contact: string;
  contactPosition: string;
  contactPhone: string;
  contactEmail: string;
  trainingRequired: boolean;
  trainingComments: string;
  publicPlacesMeters: number;
  renovationMeters: number;
  accessMeters: number;
  tabletsAvailable: boolean;
  perforedBottomsInstalled: boolean;
  loadBarsAvailable: boolean;
  linearHeight: string;
  linearWidth: string;
  photos: BenchmarkStatementPhoto[],
  products: BenchmarkStatementProduct[],
  comments: string;
  createdAt: string;
  updatedAt: string;
}

export const benchmarkStatementApi = createApi({
  reducerPath: 'benchmarkStatementApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${Config.apiBaseUrl}/benchmark-statement`, prepareHeaders }),
  endpoints: (builder) => ({
    /**
     * Get a single statement by its id.
     * @returns The statement.
     */
    getStatement: builder.query<BenchmarkStatement, number | string>({
      query: (id) => `/${id}`,
    }),

    /**
     * Get all the private statements of the user.
     * @returns The list of private statements.
     */
    getAllPrivateStatements: builder.query<BenchmarkStatementSummary[], void>({
      query: () => '/all-private',
    }),

    /**
     * Get a signed URL to upload a photo to S3.
     * @param mimeType The MIME type of the photo.
     * @returns The signed URL and the S3 key.
     */
    getStatementUploadPhotoUrl: builder.query<{ signedUrl: string, key: string }, string>({
      query: (mimeType: string) => `/photo/upload-url?mimeType=${encodeURIComponent(mimeType)}`,
    }),

    /**
     * Create a benchmark statement.
     * @param params The data of the benchmark statement.
     * @returns The created benchmark statement.
     */
    createBenchmarkStatement: builder.mutation<BenchmarkStatement, CreateBenchmarkStatementDto>({
      query: (params) => ({
        url: '/',
        method: 'POST',
        body: params,
      }),
    }),

    /**
     * Launch a statement, meaning creating an associated proposal from it.
     * @param id The id of the statement to launch.
     * @returns The updated statement.
     */
    launchBenchmarkStatement: builder.mutation<BenchmarkStatement, number>({
      query: (id) => ({
        url: `/${id}/launch`,
        method: 'PUT',
      }),
    }),

    /**
     * Update the contact information of a benchmark statement.
     * @param params The data of the benchmark statement.
     * @returns The updated benchmark statement.
     */
    updateBenchmarkStatementContact: builder.mutation<BenchmarkStatement, UpdateBenchmarkStatementContactDto>({
      query: (params) => ({
        url: `/${params.id}/contact`,
        method: 'PUT',
        body: {
          ...params,
          id: undefined,
        },
      }),
    }),
  })
});

export const {
  useGetStatementQuery,
  useLazyGetStatementQuery,
  useGetAllPrivateStatementsQuery,
  useLazyGetStatementUploadPhotoUrlQuery,
  useCreateBenchmarkStatementMutation,
  useLaunchBenchmarkStatementMutation,
  useUpdateBenchmarkStatementContactMutation
} = benchmarkStatementApi;