import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import Config from '../config';
import { prepareHeaders } from '../helpers/services';

export interface ContactPositionSuggestion {
  value: string;
}

export interface LinearHeightSuggestion {
  value: string;
}

export interface LinearWidthSuggestion {
  value: string;
}

export interface FormSuggestionsSet {
  contactPosition: ContactPositionSuggestion[];
  linearHeight: LinearHeightSuggestion[];
  linearWidth: LinearWidthSuggestion[];
}

export const formSuggestionApi = createApi({
  reducerPath: 'formSuggestionApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${Config.apiBaseUrl}/form-suggestion`, prepareHeaders }),
  endpoints: (builder) => ({
    getAllFormSuggestions: builder.query<FormSuggestionsSet, void>({
      query: () => '',
    }),
  }),
})

export const { useGetAllFormSuggestionsQuery, useLazyGetAllFormSuggestionsQuery } = formSuggestionApi;