import { useEffect, useState } from 'react';
import {
  AppBar,
  Autocomplete,
  Box,
  Card,
  Container,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useNavigate } from 'react-router-dom';
import useTranslate from '../../hooks/useTranslate';
import themeConstants from '../../theme/constants';
import { nonBreaking } from '../../utils/string';
import { Product, useGetProductsQuery, useLazyGetProductStockByRefQuery } from '../../services/product.service';
import ProductPresentation from '../../components/product/ProductPresentation';
import ProductStockRow from './components/ProductStockRow';

function WholesalerList() {
  const { t } = useTranslate();
  const navigate = useNavigate();

  const [query, setQuery] = useState<string>('');
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);

  const { data: products } = useGetProductsQuery({ query: query ?? undefined });
  const [getProductStockByRef, { data: productStock }] = useLazyGetProductStockByRefQuery();

  useEffect(() => {
    if (selectedProduct) {
      getProductStockByRef(selectedProduct.ref);
    }
  }, [selectedProduct]);

  const showEmptyMessage = selectedProduct === null || productStock === undefined || productStock.length === 0;

  return (
    <>
      <AppBar position="fixed" color="transparent" sx={{ backgroundColor: 'white', marginTop: 'var(--navbar-height)' }}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="back"
            sx={{ mr: 1 }}
            onClick={() => navigate(-1)}>
            <ChevronLeftIcon />
          </IconButton>
          <Typography variant="subtitle1" color="inherit" component="div" sx={{ flexGrow: 1 }}>
            {t('catalogue.title')}
          </Typography>
        </Toolbar>
      </AppBar>

      <Container sx={{ marginTop: `${themeConstants.navbarsHeight}px`, paddingY: 4 }}>
        <Card variant="outlined">
          <Stack direction="column" spacing={2}>
            <Autocomplete
              options={products?.edges ?? []}
              renderInput={params => <TextField {...params} label={t('product.search_ref')} required />}
              renderOption={(props, option) => {
                const propsWithoutKey = { ...props, key: undefined };
                return (
                  <Box {...propsWithoutKey} key={option.barcodeRef} component="li" sx={{ cursor: 'pointer' }}>
                    <ProductPresentation product={option} />
                  </Box>
                );
              }}
              getOptionLabel={option => option.ref}
              isOptionEqualToValue={(option, value) => option.ref === value.ref}
              filterOptions={x => x}
              noOptionsText={t('product.empty')}
              value={selectedProduct}
              onChange={(_, newValue) => setSelectedProduct(newValue)}
              inputValue={query}
              onInputChange={(_, newInputValue) => setQuery(newInputValue)}
              fullWidth
            />
            {selectedProduct && <ProductPresentation product={selectedProduct} redirectToDetailsOnClick />}
            <TableContainer>
              <Table
                aria-label="product stock table"
                sx={{ '& > tbody tr:nth-of-type(odd)': { backgroundColor: '#00000006' } }}>
                <TableHead>
                  <TableRow>
                    <TableCell>{nonBreaking(t('wholesaler.id_code'))}</TableCell>
                    <TableCell>{t('global.name')}</TableCell>
                    <TableCell>{t('global.city')}</TableCell>
                    <TableCell>{t('global.department')}</TableCell>
                    <TableCell>{t('global.address')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedProduct !== null &&
                    productStock?.map(stock => <ProductStockRow key={stock.wholesaler.id} productStock={stock} />)}
                  {showEmptyMessage && (
                    <TableRow>
                      <TableCell colSpan={6} align="center">
                        {selectedProduct !== null ? t('catalogue.no_stock') : t('catalogue.select_product')}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Stack>
        </Card>
      </Container>
    </>
  );
}

export default WholesalerList;
