export type WeekInfo = { number: number, start: Date, end: Date };

/** A utility class to handle years. */
export default class Year {
  /** The year. */
  value: number;

  constructor(year: number) {
    this.value = year;
  }

  /**
   * Get the current year.
   * @returns The current year.
   */
  static current(): Year {
    return new Year(new Date().getFullYear());
  }

  /**
   * Get the weeks of the year, with information about the start and end of each week.
   * @returns The weeks of the year.
   */
  get weeks(): WeekInfo[] {
    const weeks: WeekInfo[] = [];
    let currentDate = new Date(this.value, 0, 1);

    // Adjust start date to the first Monday of the year
    while (currentDate.getDay() !== 1) {
      currentDate.setDate(currentDate.getDate() + 1);
    }

    let weekNumber = 1;

    while (currentDate.getFullYear() === this.value) {
      const startOfWeek = new Date(currentDate); // Monday
      const endOfWeek = new Date(currentDate);
      endOfWeek.setDate(currentDate.getDate() + 6); // Sunday
      endOfWeek.setHours(23, 59, 59, 999);

      weeks.push({ number: weekNumber, start: startOfWeek, end: endOfWeek });

      // Move to the next Monday
      currentDate.setDate(currentDate.getDate() + 7);
      weekNumber++;
    }

    return weeks;
  }

  /**
   * Get the current week.
   * @returns The current week.
   */
  get currentWeek(): WeekInfo {
    for (const week of this.weeks) {
      if (week.start <= new Date() && new Date() <= week.end) {
        return week;
      }
    }

    return this.weeks[0];
  }

  /**
   * Get the last years as a number.
   * @param n The number of years to get.
   * @returns The last N years, as `number`.
   */
  getLastYears(n: number): number[] {
    const years: number[] = [];
    for (let i = this.value; i >= this.value - n; i--) {
      years.push(i);
    }

    return years;
  }

  /** Returns a string representation of a year. */
  toString() {
    return this.value.toString();
  }
}