import React, { useEffect } from 'react';
import {
  Card,
  FormControl,
  Hidden,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import useTranslate from '../../../../hooks/useTranslate';
import useNetwork from '../../../../hooks/useNetwork';
import { useGetAllVisitReportsQuery } from '../../../../services/intervention-report.service';
import VisitReportRow from './VisitReportRow';
import { useAppSelector } from '../../../../store';
import { useGetMySubordinatesQuery, UserRole, UserSummary } from '../../../../services/user.service';
import Year, { WeekInfo } from '../../../../utils/year';
import VisitReportsDataPaper from './VisitReportsDataPaper';
import { getFullName, getSubordinatesByRole } from '../../../../helpers/user';

function VisitReportsCard() {
  const { online } = useNetwork();
  const { t, localeDate } = useTranslate();
  const { user } = useAppSelector(state => state.auth);

  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const currentYear = Year.current();
  const [weeks, setWeeks] = React.useState<WeekInfo[]>(currentYear.weeks);

  const [selectedYear, setSelectedYear] = React.useState<number>(currentYear.value);
  const [selectedWeek, setSelectedWeek] = React.useState<WeekInfo | null>(null);
  const [selectedCoordinator, setSelectedCoordinator] = React.useState<UserSummary | null>(null);

  const { data, isLoading } = useGetAllVisitReportsQuery({
    coordinatorId: selectedCoordinator?.id,
    startDate: selectedWeek?.start ?? new Date(selectedYear, 0, 1, 0, 0, 0, 0),
    endDate: selectedWeek?.end ?? new Date(selectedYear, 11, 31, 23, 59, 59, 999),
  });

  const { data: subordinates } = useGetMySubordinatesQuery();

  if (!online) {
    return null;
  }

  const salesCoordinators =
    user?.role !== UserRole.SALES_COORDINATOR
      ? getSubordinatesByRole(UserRole.SALES_COORDINATOR, subordinates ?? [])
      : [];

  return (
    <Card variant="outlined">
      <Stack direction={isTablet ? 'column' : 'row'} spacing={2} my={2}>
        <FormControl size="small" sx={{ minWidth: 100 }} fullWidth>
          <InputLabel id="year-select-label">{t('global.year')}</InputLabel>
          <Select
            labelId="year-select-label"
            id="year-select"
            value={selectedYear}
            label={t('global.year')}
            onChange={evt => {
              const year = new Year(Number(evt.target.value));
              setSelectedYear(year.value);
              setWeeks(year.weeks);
              setSelectedWeek(null);
            }}>
            {currentYear.getLastYears(10).map(year => (
              <MenuItem key={year} value={year}>
                {year}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl size="small" sx={{ minWidth: 100 }} fullWidth>
          <InputLabel id="week-select-label">{t('global.week')}</InputLabel>
          <Select
            labelId="week-select-label"
            id="week-select"
            value={selectedWeek?.number ?? 'all'}
            label={t('global.week')}
            onChange={evt => {
              if (evt.target.value === 'all') {
                setSelectedWeek(null);
                return;
              }

              const week = weeks.find(w => w.number === Number(evt.target.value));
              if (!week) return;
              setSelectedWeek(week);
            }}>
            <MenuItem value="all">{t('global.all_female')}</MenuItem>
            {weeks.map(week => (
              <MenuItem key={week.number} value={week.number}>
                {week.number}&nbsp;
                <Typography variant="caption" component="small" color="text.secondary">
                  {localeDate(week.start, 'short')} - {localeDate(week.end, 'short')}
                </Typography>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {user?.role !== UserRole.SALES_COORDINATOR && (
          <FormControl size="small" sx={{ minWidth: 100 }} fullWidth>
            <InputLabel id="user-select-label">{t('user.role.sales_coordinator')}</InputLabel>
            <Select
              labelId="user-select-label"
              id="user-select"
              value={selectedCoordinator?.id ?? 'all'}
              label={t('user.role.sales_coordinator')}
              onChange={evt => {
                if (evt.target.value === 'all') {
                  setSelectedCoordinator(null);
                  return;
                }

                const coordinator = salesCoordinators.find(c => c.id === Number(evt.target.value));
                if (!coordinator) return;
                setSelectedCoordinator(coordinator);
              }}>
              <MenuItem value="all">{t('global.all_male')}</MenuItem>
              {salesCoordinators.map(coordinator => (
                <MenuItem key={coordinator.id} value={coordinator.id}>
                  {getFullName(coordinator)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Stack>

      <VisitReportsDataPaper reports={data} />

      <TableContainer component={props => <Paper variant="outlined" {...props} />}>
        <Table
          aria-label="visit reports table"
          sx={{ '& > tbody tr:nth-of-type(odd)': { backgroundColor: '#00000006' } }}>
          <TableHead>
            <TableRow>
              <TableCell>{t('report.visit.date')}</TableCell>
              <Hidden smDown>
                {user!.role !== UserRole.SALES_COORDINATOR && <TableCell>{t('user.role.sales_coordinator')}</TableCell>}
                <TableCell>{t('report.visit.name')}</TableCell>
                <TableCell>{t('report.visit.zip')}</TableCell>
                <TableCell>{t('report.visit.city')}</TableCell>
              </Hidden>
              <TableCell>{t('report.visit.type')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map(report => (
              <React.Fragment key={report.id}>
                <VisitReportRow report={report} />
              </React.Fragment>
            ))}

            {data?.length === 0 && !isLoading && (
              <TableRow>
                <TableCell colSpan={user?.role !== UserRole.SALES_COORDINATOR ? 6 : 5} align="center">
                  {selectedWeek === null
                    ? t('report.visit.empty_for_year', { year: selectedYear })
                    : t('report.visit.empty_for_week', { week: selectedWeek.number, year: selectedYear })}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
}

export default VisitReportsCard;
