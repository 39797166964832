import { Paginated } from "../services/types";

/** The default page size for pagination. */
export const DEFAULT_PAGE_SIZE = 10;

/**
 * Paginate a list of data.
 * @param data The data to paginate.
 * @param page The page number to paginate.
 * @param pageSize The page size to paginate.
 * @returns The paginated data.
 */
export function paginate<T>(data: T[], page: number, pageSize: number = DEFAULT_PAGE_SIZE): Paginated<T> {
  const startIndex = (page - 1) * pageSize;
  const endIndex = Math.min(startIndex + pageSize, data.length);
  return {
    edges: data.slice(startIndex, endIndex),
    pageInfo: {
      numberOfPages: Math.ceil(data.length / pageSize),
      hasNextPage: endIndex < data.length,
      hasPreviousPage: startIndex > 0,
      startCursor: startIndex,
      endCursor: endIndex - 1,
    },
  }
}