import {
  Autocomplete,
  Button,
  Card,
  Dialog,
  Divider,
  Fab,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  ImageList,
  ImageListItem,
  Paper,
  Radio,
  RadioGroup,
  Snackbar,
  Stack,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { getPhotoUrl } from '../../../../helpers/benchmark-statement';
import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import {
  BenchmarkStatement,
  BenchmarkStatementPhoto,
  useUpdateBenchmarkStatementContactMutation,
} from '../../../../services/benchmark-statement.service';
import { Close } from '@mui/icons-material';
import { useSearchParams } from 'react-router-dom';
import useTranslate from '../../../../hooks/useTranslate';
import { useAppSelector } from '../../../../store';
import { UserRole } from '../../../../services/user.service';
import { useGetAllFormSuggestionsQuery } from '../../../../services/form-suggestion.service';

export interface LayoutCardProps {
  /** The proposal to display the layout for. */
  statement: BenchmarkStatement;
}

function LayoutCard({ statement }: LayoutCardProps) {
  const isArchived = statement.wholesaler.deletedAt !== null;

  const { t } = useTranslate();

  const [searchParams, setSearchParams] = useSearchParams();

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const user = useAppSelector(state => state.auth.user);
  const canEditContact = (user?.role === UserRole.SALES || user?.role === UserRole.SALES_COORDINATOR) && !isArchived;

  const { data: contactPositionSuggestions } = useGetAllFormSuggestionsQuery();

  const [updateBenchmarkStatementContact] = useUpdateBenchmarkStatementContactMutation();

  const [selectedPhoto, setSelectedPhoto] = useState<BenchmarkStatementPhoto | null>(null);

  const [statementContact, setStatementContact] = useState<string>(statement.contact);
  const [statementContactPosition, setStatementContactPosition] = useState<string>(statement.contactPosition);
  const [statementContactPhone, setStatementContactPhone] = useState<string>(statement.contactPhone);
  const [statementContactEmail, setStatementContactEmail] = useState<string>(statement.contactEmail);

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  /**
   * Handle a photo selected event.
   * @param photo The photo to select.
   */
  function handlePhotoSelected(photo: BenchmarkStatementPhoto) {
    setSearchParams(prev => {
      prev.set('photo', photo.key);
      return prev;
    });
  }

  /** Handle a photo preview close request. */
  function handlePhotoClose() {
    setSearchParams(prev => {
      prev.delete('photo');
      return prev;
    });
  }

  function handleUpdateContactSubmit(evt: React.FormEvent<HTMLFormElement>) {
    evt.preventDefault();
    evt.stopPropagation();

    if (!canEditContact) {
      return;
    }

    updateBenchmarkStatementContact({
      id: statement.id,
      contact: statementContact,
      contactPosition: statementContactPosition,
      contactPhone: statementContactPhone,
      contactEmail: statementContactEmail,
    })
      .unwrap()
      .then(() => {
        setSnackbarMessage(t('statement.contact.updated'));
      })
      .catch(() => {
        setSnackbarMessage(t('statement.contact.updated.error'));
      })
      .finally(() => {
        setShowSnackbar(true);
      });
  }

  // Update the currently selected photo from the URL
  useEffect(() => {
    const photoKey = searchParams.get('photo');
    const photo = statement.photos.find(p => p.key === photoKey);
    setSelectedPhoto(photo ?? null);
  }, [searchParams]);

  return (
    <>
      <Card variant="outlined" component="section">
        <Typography variant="h6" component="h1" mb={2}>
          {t('statement.layout')}
        </Typography>
        <Divider />

        <form>
          <Paper variant="outlined" sx={{ padding: 2, marginTop: 2 }}>
            <FormGroup>
              <FormLabel component="legend" sx={{ marginBottom: 2 }}>
                {t('statement.create.form.linear_meters_implanted_label')}
              </FormLabel>
              <Stack direction={isMobile ? 'column' : 'row'} spacing={2}>
                <TextField
                  type="number"
                  variant="outlined"
                  label={t('market.public_places')}
                  value={statement.publicPlacesMeters ?? ''}
                  fullWidth
                  disabled
                />
                <TextField
                  type="number"
                  variant="outlined"
                  label={t('market.renovation')}
                  value={statement.renovationMeters ?? ''}
                  fullWidth
                  disabled
                />
                <TextField
                  type="number"
                  variant="outlined"
                  label={t('market.access')}
                  value={statement.accessMeters ?? ''}
                  fullWidth
                  disabled
                />
              </Stack>
            </FormGroup>
          </Paper>

          <Stack direction={isMobile ? 'column' : 'row'} spacing={2} mt={2}>
            <Paper variant="outlined" sx={{ padding: 2, marginTop: 2, flexGrow: 1 }}>
              <FormControl disabled>
                <FormLabel id="tablets-available-label">{t('statement.create.form.available_tablets_label')}</FormLabel>
                <RadioGroup
                  aria-labelledby="tablets-available-label"
                  value={statement.tabletsAvailable ? 'yes' : 'no'}
                  name="tablets-available"
                  row>
                  <FormControlLabel value="yes" control={<Radio />} label={t('global.yes')} />
                  <FormControlLabel value="no" control={<Radio />} label={t('global.no')} />
                </RadioGroup>
              </FormControl>
            </Paper>
            <Paper variant="outlined" sx={{ padding: 2, marginTop: 2, flexGrow: 1 }}>
              <FormControl fullWidth disabled>
                <FormLabel id="perfored-bottoms-installed-label">
                  {t('statement.create.form.perforated_bottoms_label')}
                </FormLabel>
                <RadioGroup
                  aria-labelledby="perfored-bottoms-installed-label"
                  value={statement.perforedBottomsInstalled ? 'yes' : 'no'}
                  name="perfored-bottoms-installed"
                  row>
                  <FormControlLabel value="yes" control={<Radio />} label={t('global.yes')} />
                  <FormControlLabel value="no" control={<Radio />} label={t('global.no')} />
                </RadioGroup>
              </FormControl>
            </Paper>
            {!statement.perforedBottomsInstalled && (
              <Paper variant="outlined" sx={{ padding: 2, marginTop: 2, flexGrow: 1 }}>
                <FormControl fullWidth disabled>
                  <FormLabel id="load-bars-available-label">
                    {' '}
                    {t('statement.create.form.available_load_bars_label')}
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="load-bars-available-label"
                    value={statement.loadBarsAvailable ? 'yes' : 'no'}
                    name="load-bars-available"
                    row>
                    <FormControlLabel value="yes" control={<Radio />} label={t('global.yes')} />
                    <FormControlLabel value="no" control={<Radio />} label={t('global.no')} />
                  </RadioGroup>
                </FormControl>
              </Paper>
            )}
          </Stack>

          <Paper variant="outlined" sx={{ padding: 2, marginTop: 2 }}>
            <FormGroup sx={{ marginBottom: 2 }}>
              <FormLabel component="legend" sx={{ marginBottom: 2 }}>
                {t('statement.create.form.linear_meters_label')}
              </FormLabel>
              <Stack direction={isMobile ? 'column' : 'row'} spacing={2}>
                <TextField
                  variant="outlined"
                  label={t('statement.create.form.linear_height_label')}
                  value={statement.linearHeight ?? ''}
                  fullWidth
                  disabled
                />
                <TextField
                  variant="outlined"
                  label={t('statement.create.form.linear_width_label')}
                  value={statement.linearWidth ?? ''}
                  fullWidth
                  disabled
                />
              </Stack>
            </FormGroup>
            <FormGroup>
              <FormLabel component="legend">{t('photos.title')}</FormLabel>
              <ImageList cols={isMobile ? 3 : isTablet ? 6 : 10} gap={4}>
                {statement.photos.map(photo => (
                  <PhotoButton key={photo.key} type="button" onClick={() => handlePhotoSelected(photo)}>
                    <ImageListItem>
                      <img src={getPhotoUrl(photo.key)} loading="lazy" />
                    </ImageListItem>
                  </PhotoButton>
                ))}
              </ImageList>
            </FormGroup>
          </Paper>
        </form>
      </Card>

      <Card variant="outlined" component="section" sx={{ mt: 2 }}>
        <Typography variant="h6" component="h1" mb={2}>
          {t('statement.contact')}
        </Typography>
        <Divider />

        <form onSubmit={handleUpdateContactSubmit}>
          <Paper variant="outlined" sx={{ padding: 2, marginTop: 2 }}>
            <FormGroup>
              <FormLabel component="legend" sx={{ marginBottom: 2 }}>
                {t('statement.contact_details')}
              </FormLabel>
              <Stack direction={isMobile ? 'column' : 'row'} spacing={2}>
                <TextField
                  variant="outlined"
                  label={t('statement.create.form.contact_label')}
                  value={statementContact ?? ''}
                  onChange={evt => setStatementContact(evt.target.value)}
                  fullWidth
                  disabled={!canEditContact}
                  required={canEditContact}
                />
                <Autocomplete
                  id="position"
                  options={contactPositionSuggestions?.contactPosition.map(s => s.value) ?? []}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label={t('statement.create.form.contact_position_label')}
                      required={canEditContact}
                    />
                  )}
                  inputValue={statementContactPosition}
                  onInputChange={(_, value) => setStatementContactPosition(value ?? '')}
                  disabled={!canEditContact}
                  freeSolo
                  fullWidth
                />
                <TextField
                  variant="outlined"
                  label={t('statement.create.form.contact_phone_label')}
                  value={statementContactPhone ?? ''}
                  onChange={evt => setStatementContactPhone(evt.target.value)}
                  fullWidth
                  disabled={!canEditContact}
                  required={canEditContact}
                />
                <TextField
                  variant="outlined"
                  label={t('statement.create.form.contact_email_label')}
                  value={statementContactEmail ?? ''}
                  onChange={evt => setStatementContactEmail(evt.target.value)}
                  fullWidth
                  disabled={!canEditContact}
                />
              </Stack>
              {canEditContact && (
                <Stack direction="row" spacing={2} mt={2} justifyContent="flex-end">
                  <Button type="submit" variant="contained" sx={{ flex: isMobile ? 1 : undefined }}>
                    {t('global.update')}
                  </Button>
                </Stack>
              )}
            </FormGroup>
          </Paper>
        </form>
      </Card>

      <Dialog fullScreen open={selectedPhoto !== null} onClose={handlePhotoClose}>
        {selectedPhoto !== null && (
          <PhotoViewerContainer>
            <PhotoViewer style={{ backgroundImage: `url(${getPhotoUrl(selectedPhoto.key)})` }} />
            <Fab
              color="primary"
              size="medium"
              sx={{ position: 'absolute', top: 20, right: 20 }}
              aria-label="close"
              onClick={handlePhotoClose}>
              <Close />
            </Fab>
          </PhotoViewerContainer>
        )}
      </Dialog>

      <Snackbar
        sx={{ display: 'flex', justifyContent: 'center' }}
        open={showSnackbar}
        autoHideDuration={2000}
        onClose={() => setShowSnackbar(false)}
        message={snackbarMessage}
      />
    </>
  );
}

const PhotoButton = styled.button`
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
`;

const PhotoViewerContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const PhotoViewer = styled.div`
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
`;

export default LayoutCard;
