import { Chip, TableCell, TableRow } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getFullName } from '../../../../helpers/user';
import useTranslate from '../../../../hooks/useTranslate';
import { InterventionReportSummaryWithSales } from '../../../../services/intervention-report.service';
import { BenchmarkProposalStatus } from '../../../../services/benchmark-proposal.service';
import { getStatusColor } from '../../../../helpers/benchmark-proposal';

export interface WholesalerReportRowProps {
  report: InterventionReportSummaryWithSales;
}

function WholesalerReportRow({ report }: WholesalerReportRowProps) {
  const { t, localeDate } = useTranslate();
  const navigate = useNavigate();

  return (
    <TableRow
      key={report.id}
      component="a"
      sx={{
        '&:last-child td, &:last-child th': { border: 0 },
        textDecoration: 'none',
        userSelect: 'none',
      }}
      href={`/report/${report.id}`}>
      <TableCell>{localeDate(report.createdAt, 'short')}</TableCell>
      <TableCell>{report.sales ? getFullName(report.sales) : '-'}</TableCell>
      <TableCell>
        <Chip
          label={t(`benchmark_proposal.status.${BenchmarkProposalStatus.DONE}`)}
          size="small"
          color={getStatusColor(BenchmarkProposalStatus.DONE)}
        />
      </TableCell>
    </TableRow>
  );
}

export default WholesalerReportRow;
