import { Hidden, TableCell, TableRow, Typography } from '@mui/material';
import useTranslate from '../../../../hooks/useTranslate';
import { BenchmarkStatementSummary } from '../../../../services/benchmark-statement.service';

export interface PrivateStatementRowProps {
  statement: BenchmarkStatementSummary;
}

function PrivateStatementRow({ statement }: PrivateStatementRowProps) {
  const { localeDate } = useTranslate();

  return (
    <TableRow
      component="a"
      sx={{
        '&:last-child td, &:last-child th': { border: 0 },
        textDecoration: 'none',
        userSelect: 'none',
      }}
      href={`/benchmark-statement/${statement.id}`}>
      <TableCell>
        <Typography variant="body2">{localeDate(statement.createdAt, 'short')}</Typography>
      </TableCell>
      <TableCell>{statement.wholesaler.name}</TableCell>
      <Hidden smDown>
        <TableCell>{statement.wholesaler.zip}</TableCell>
        <TableCell>{statement.wholesaler.city}</TableCell>
      </Hidden>
    </TableRow>
  );
}

export default PrivateStatementRow;
