import { useState } from 'react';
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormGroup,
  IconButton,
  Stack,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import {
  BenchmarkProposal,
  BenchmarkProposalProductAdjustment,
  ProductAdjustmentStatus,
  useDeleteBenchmarkProposalAdjustmentMutation,
  useUpdateBenchmarkProposalAdjustmentMutation,
} from '../../../../services/benchmark-proposal.service';
import ProductPresentation from '../../../../components/product/ProductPresentation';
import useTranslate from '../../../../hooks/useTranslate';
import {
  formatAdjustmentQuantity,
  getAjustmentQuantityColor,
  getProductAdjustmentStatusColor,
} from '../../../../helpers/benchmark-proposal';
import RemoveIcon from '@mui/icons-material/Remove';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import { useAppSelector } from '../../../../store';
import { UserRole } from '../../../../services/user.service';

export interface ProposalAdjustmentRowProps {
  /** The proposal the row comes from. */
  proposal: BenchmarkProposal;
  /** The adjustment of to display in the row. */
  adjustment: BenchmarkProposalProductAdjustment;
  /** Whether the form is disabled. */
  isFormDisabled?: boolean;
  /** Callback fired when the adjustment is deleted. */
  onAdjustmentDeleted?: () => void;
  /** Callback fired when the adjustment is updated. */
  onAdjustmentUpdated?: () => void;
  /** Callback fired when the adjustment is moved. */
  onAdjustmentMoved?: () => void;
}

function ProposalAdjustmentRow({
  proposal,
  adjustment,
  isFormDisabled,
  onAdjustmentDeleted,
  onAdjustmentUpdated,
  onAdjustmentMoved,
}: ProposalAdjustmentRowProps) {
  const { user } = useAppSelector(state => state.auth);

  const { t } = useTranslate();

  const [showMoveDialog, setShowMoveDialog] = useState(false);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const [quantity, setQuantity] = useState<number | null>(adjustment.quantityAdjustment);

  const [deleteProductAdjustment] = useDeleteBenchmarkProposalAdjustmentMutation();
  const [updateProductAdjustment] = useUpdateBenchmarkProposalAdjustmentMutation();

  const statementProduct = proposal.benchmarkStatement.products.find(
    item => item.product.ref === adjustment.product.ref,
  );

  const layoutProduct = proposal.benchmarkStatement.benchmarkLayout.products.find(
    item => item.product.ref === adjustment.product.ref,
  );

  let actionTargetStatus: ProductAdjustmentStatus | null = null;
  if (
    [ProductAdjustmentStatus.KEEP, ProductAdjustmentStatus.RESTOCK, ProductAdjustmentStatus.OUT_OF_SALES_PLAN].includes(
      adjustment.status,
    )
  ) {
    actionTargetStatus = ProductAdjustmentStatus.REMOVE;
  } else if (adjustment.status === ProductAdjustmentStatus.REMOVE) {
    actionTargetStatus = Boolean(layoutProduct)
      ? ProductAdjustmentStatus.KEEP
      : ProductAdjustmentStatus.OUT_OF_SALES_PLAN;
  }

  function onMoveSubmit(evt: React.FormEvent<HTMLFormElement>) {
    evt.preventDefault();
    evt.stopPropagation();

    if ([ProductAdjustmentStatus.MISSING, ProductAdjustmentStatus.UNKNOWN].includes(adjustment.status)) {
      return;
    }

    let newAdjustmentQuantity: number | null = null;
    if (
      actionTargetStatus === ProductAdjustmentStatus.KEEP ||
      actionTargetStatus === ProductAdjustmentStatus.OUT_OF_SALES_PLAN
    ) {
      newAdjustmentQuantity = 0;
    } else if (actionTargetStatus === ProductAdjustmentStatus.REMOVE) {
      newAdjustmentQuantity = -(statementProduct?.quantity ?? 0);
    }

    if (newAdjustmentQuantity === null) return;

    updateProductAdjustment({
      benchmarkProposalId: proposal.id,
      adjustmentId: adjustment.id,
      quantity: newAdjustmentQuantity,
    })
      .unwrap()
      .then(() => {
        setShowMoveDialog(false);
        onAdjustmentMoved?.();
      })
      .catch(() => {});
  }

  function onUpdateSubmit(evt: React.FormEvent<HTMLFormElement>) {
    evt.preventDefault();
    evt.stopPropagation();

    updateProductAdjustment({
      benchmarkProposalId: proposal.id,
      adjustmentId: adjustment.id,
      quantity: quantity ?? 0,
    })
      .unwrap()
      .then(() => {
        setShowEditDialog(false);
        onAdjustmentUpdated?.();
      })
      .catch(() => {});
  }

  function onDeleteSubmit(evt: React.FormEvent<HTMLFormElement>) {
    evt.preventDefault();
    evt.stopPropagation();

    deleteProductAdjustment({
      benchmarkProposalId: proposal.id,
      adjustmentId: adjustment.id,
    })
      .unwrap()
      .then(() => {
        setShowDeleteDialog(false);
        onAdjustmentDeleted?.();
      })
      .catch(() => {});
  }

  return (
    <>
      <TableRow key={adjustment.id}>
        <TableCell>
          <ProductPresentation product={adjustment.product} redirectToDetailsOnClick />
        </TableCell>
        <TableCell>
          <Chip
            label={t(`product_adjustment.status.${adjustment.status}`)}
            size="small"
            color={getProductAdjustmentStatusColor(adjustment.status)}
          />
        </TableCell>
        <TableCell>
          <Typography variant="body2">{statementProduct?.quantity ?? 0}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2" color={getAjustmentQuantityColor(adjustment.quantityAdjustment)} fontWeight={500}>
            {formatAdjustmentQuantity(adjustment.quantityAdjustment)}
          </Typography>
        </TableCell>
        <TableCell>{layoutProduct?.minimumQuantity ?? '-'}</TableCell>
        {user?.role === UserRole.SALES && (
          <TableCell align="right">
            <Stack direction="row" spacing={1}>
              {adjustment.status === ProductAdjustmentStatus.MISSING ? (
                <IconButton
                  aria-label="delete"
                  color="secondary"
                  onClick={() => setShowDeleteDialog(true)}
                  disabled={isFormDisabled}>
                  <DeleteIcon fontSize="small" />
                </IconButton>
              ) : (
                <IconButton
                  aria-label="move"
                  color="secondary"
                  onClick={() => setShowMoveDialog(true)}
                  disabled={isFormDisabled || !actionTargetStatus}
                  sx={{ opacity: actionTargetStatus ? 1 : 0 }}>
                  {actionTargetStatus === ProductAdjustmentStatus.REMOVE ? (
                    <RemoveIcon fontSize="medium" />
                  ) : (
                    <AddIcon fontSize="medium" />
                  )}
                </IconButton>
              )}
              <IconButton
                aria-label="edit"
                color="secondary"
                onClick={() => setShowEditDialog(true)}
                disabled={isFormDisabled}>
                <EditIcon fontSize="small" />
              </IconButton>
            </Stack>
          </TableCell>
        )}
      </TableRow>

      <Dialog
        open={showEditDialog}
        onClose={() => setShowEditDialog(false)}
        aria-labelledby="edit-adjustment-title"
        aria-describedby="edit-adjustment-description">
        <form onSubmit={onUpdateSubmit}>
          <DialogTitle id="edit-adjustment-title">{t('product_adjustment.edit_quantity')}</DialogTitle>
          <DialogContent>
            <ProductPresentation product={adjustment.product} />
            <FormGroup sx={{ mt: 2 }}>
              <TextField
                type="number"
                label={t('product_adjustment.value')}
                variant="outlined"
                value={quantity ?? ''}
                onChange={evt => setQuantity(evt.target.value === '' ? null : Number(evt.target.value))}
                fullWidth
                autoFocus
                required
              />
            </FormGroup>
          </DialogContent>
          <DialogActions>
            <Button type="button" variant="outlined" onClick={() => setShowEditDialog(false)}>
              {t('global.cancel')}
            </Button>
            <Button type="submit" variant="contained">
              {t('global.confirm')}
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <Dialog
        open={showMoveDialog}
        onClose={() => setShowMoveDialog(false)}
        aria-labelledby="move-adjustment-title"
        aria-describedby="move-adjustment-description">
        <form onSubmit={onMoveSubmit}>
          <DialogTitle id="move-adjustment-title">{t('product_adjustment.confirm_move')}</DialogTitle>
          <DialogContent>
            <ProductPresentation product={adjustment.product} />
            <Typography variant="body1" sx={{ mt: 2 }}>
              {t(`product_adjustment.move_to_${actionTargetStatus ?? ProductAdjustmentStatus.UNKNOWN}`)}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button type="button" variant="outlined" onClick={() => setShowMoveDialog(false)}>
              {t('global.cancel')}
            </Button>
            <Button type="submit" variant="contained" autoFocus>
              {t('global.confirm')}
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <Dialog
        open={showDeleteDialog}
        onClose={() => setShowDeleteDialog(false)}
        aria-labelledby="delete-adjustment-title"
        aria-describedby="delete-adjustment-description">
        <form onSubmit={onDeleteSubmit}>
          <DialogTitle id="delete-adjustment-title">{t('product_adjustment.confirm_delete')}</DialogTitle>
          <DialogContent>
            <ProductPresentation product={adjustment.product} />
            <Typography variant="body1" sx={{ mt: 2 }}>
              {t('product_adjustment.delete_description')}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button type="button" variant="outlined" onClick={() => setShowDeleteDialog(false)}>
              {t('global.cancel')}
            </Button>
            <Button type="submit" variant="contained" autoFocus>
              {t('global.confirm')}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}

export default ProposalAdjustmentRow;
