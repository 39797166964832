import { useEffect, useState } from 'react';
import { Backdrop, Button, CircularProgress, Container, Hidden } from '@mui/material';
import CreateReportFormNavbar from './components/layout/CreateReportFormNavbar';
import DesktopForbiddenPage from '../../../components/layout/DesktopForbiddenPage';
import useTranslate from '../../../hooks/useTranslate';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useCreateReportForm, {
  CREATE_REPORT_FORM_SCAN_STEP,
  MAX_CREATE_REPORT_FORM_STEP,
  MIN_CREATE_REPORT_FORM_STEP,
  REPORT_INTERVENTION_FORM_STEP,
} from './hooks/useCreateReportForm';
import { useAppDispatch, useAppSelector } from '../../../store';
import CreateReportStep1 from './components/steps/CreateReportStep1';
import CreateReportFormBottomBar from './components/layout/CreateReportFormBottomBar';
import CreateReportStep2 from './components/steps/CreateReportStep2';
import CreateReportStep3 from './components/steps/CreateReportStep3';
import CreateReportStep4 from './components/steps/CreateReportStep4';
import CreateReportStep5 from './components/steps/CreateReportStep5';
import { resetCreateReportFormState } from '../../../store/slices/create-report-form.slice';
import themeConstants from '../../../theme/constants';
import useSaveReport from './hooks/useSaveReport';
import useNetwork from '../../../hooks/useNetwork';
import { addReport } from '../../../store/slices/offline.slice';

function CreateInterventionReport() {
  const { t } = useTranslate();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const { online } = useNetwork();

  const dispatch = useAppDispatch();
  const createReportFormState = useAppSelector(state => state.createReportForm);

  const [showProductList, setShowProductList] = useState<boolean>(searchParams.get('showProductList') === 'true');

  const { goToStep, isCurrentStepValid, cleanCurrentStep } = useCreateReportForm();

  const saveReport = useSaveReport();

  const [isCreatingReport, setIsCreatingReport] = useState<boolean>(false);

  function renderStep(step: number): JSX.Element | null {
    switch (step) {
      case 1:
        return <CreateReportStep1 />;
      case 2:
        return <CreateReportStep2 />;
      case 3:
        return (
          <CreateReportStep3
            showProductList={showProductList}
            onHideProductList={() => {
              searchParams.delete('showProductList');
              setSearchParams(searchParams);
            }}
          />
        );
      case 4:
        return <CreateReportStep4 />;
      case 5:
        return <CreateReportStep5 />;
      default:
        return null;
    }
  }

  function goBack() {
    if (createReportFormState.step === REPORT_INTERVENTION_FORM_STEP + 2 && !createReportFormState.isImplementation) {
      goToStep(step - 3);
      return;
    }

    goToStep(step - 1);
  }

  function handleSubmit(evt: React.FormEvent<HTMLFormElement>) {
    evt.preventDefault();
    evt.stopPropagation();

    if (step !== MAX_CREATE_REPORT_FORM_STEP) {
      cleanCurrentStep();

      if (createReportFormState.step === REPORT_INTERVENTION_FORM_STEP && !createReportFormState.isImplementation) {
        goToStep(step + 3);
      } else {
        goToStep(step + 1);
      }
      return;
    }

    // If the user is offline, save the statement in the offline store.
    if (!online) {
      dispatch(addReport(createReportFormState));
      dispatch(resetCreateReportFormState());
      navigate(`/`);
      return;
    }

    setIsCreatingReport(true);
    saveReport(createReportFormState)
      .then(report => {
        dispatch(resetCreateReportFormState());
        if (report.benchmarkProposal) {
          navigate(`/benchmark-proposal/${report.benchmarkProposal.id}`);
        } else {
          navigate(`/report/${report.id}`);
        }
      })
      .catch(() => {
        alert(t('global.error.unknown'));
      })
      .finally(() => {
        setIsCreatingReport(false);
      });
  }

  useEffect(() => {
    setShowProductList(searchParams.get('showProductList') === 'true');
  }, [searchParams]);

  const step = createReportFormState.step;

  let nextStepMessage = t('global.next');
  if (step === 1 && !isCurrentStepValid) {
    nextStepMessage = t('report.error.select_wholesaler');
  } else if (step === 3) {
    nextStepMessage = t('report.create.form.listing_over');
  } else if (step === 5) {
    nextStepMessage = t('global.finalize');
  }

  return (
    <>
      <CreateReportFormNavbar
        step={step}
        onBackClick={goBack}
        onToggleProductList={() => {
          if (showProductList) {
            searchParams.delete('showProductList');
          } else {
            searchParams.set('showProductList', 'true');
          }
          setSearchParams(searchParams);
        }}
      />

      <form onSubmit={handleSubmit}>
        <Container
          sx={{
            paddingY: step !== CREATE_REPORT_FORM_SCAN_STEP ? '1.25rem' : 0,
            paddingX: step !== CREATE_REPORT_FORM_SCAN_STEP ? undefined : 0,
            marginBottom: step !== CREATE_REPORT_FORM_SCAN_STEP ? `${themeConstants.navbarsHeight}px` : 0,
            marginTop: `${themeConstants.navbarsHeight}px`,
          }}>
          {renderStep(step)}
        </Container>

        <CreateReportFormBottomBar
          actionsElement={
            <>
              {step > MIN_CREATE_REPORT_FORM_STEP && (
                <Button type="button" variant="outlined" size="medium" onClick={goBack} fullWidth>
                  {t('global.previous')}
                </Button>
              )}
              <Button type="submit" variant="contained" size="medium" disabled={!isCurrentStepValid} fullWidth>
                {nextStepMessage}
              </Button>
            </>
          }
        />
      </form>

      <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={isCreatingReport}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

export default CreateInterventionReport;
