import { useEffect, useState } from 'react';
import {
  AppBar,
  Box,
  Card,
  Container,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useNavigate } from 'react-router-dom';
import useTranslate from '../../../hooks/useTranslate';
import SearchInput from '../../../components/ui/form/SearchInput';
import { Wholesaler } from '../../../services/wholesaler.service';
import { FrenchDepartments } from '../../../helpers/departments';
import { nonBreaking } from '../../../utils/string';
import WholesalerRow from './components/WholesalerRow';
import themeConstants from '../../../theme/constants';
import { Paginated } from '../../../services/types';
import { useAppSelector } from '../../../store';
import { paginate } from '../../../helpers/pagination';
import { searchWholesalers, sortWholesalers, WholesalerSortMethod } from '../../../helpers/wholesaler';
import Year from '../../../utils/year';

function WholesalerList() {
  const { t } = useTranslate();
  const navigate = useNavigate();

  const wholesalers = useAppSelector(state => state.offline.wholesalers);

  const [page, setPage] = useState<number>(1);
  const [query, setQuery] = useState<string>('');
  const [year, setYear] = useState<number | 'all'>('all');
  const [sortBy, setSortBy] = useState<WholesalerSortMethod>('name');

  const [data, setData] = useState<Paginated<Wholesaler> | null>(null);

  function formatQuery(query: string) {
    const departement = FrenchDepartments.find(department => department.name.toLowerCase() === query.toLowerCase());
    if (departement) {
      return departement.code;
    }
    return query;
  }

  useEffect(() => {
    let filteredWholesalers = searchWholesalers(formatQuery(query), wholesalers);
    if (year !== 'all') {
      filteredWholesalers = filteredWholesalers.filter(w => {
        const y = w.interventionReports[0] ? new Date(w.interventionReports[0].createdAt).getFullYear() : null;
        return y === year;
      });
    }
    const sortedWholesalers = sortWholesalers(filteredWholesalers, sortBy);
    setData(paginate(sortedWholesalers, page));
  }, [page, query, year, sortBy, wholesalers]);

  return (
    <>
      <AppBar position="fixed" color="transparent" sx={{ backgroundColor: 'white', marginTop: 'var(--navbar-height)' }}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="back"
            sx={{ mr: 1 }}
            onClick={() => navigate(-1)}>
            <ChevronLeftIcon />
          </IconButton>
          <Typography variant="subtitle1" color="inherit" component="div" sx={{ flexGrow: 1 }}>
            {t('wholesaler.list')}
          </Typography>
        </Toolbar>
      </AppBar>

      <Container sx={{ marginTop: `${themeConstants.navbarsHeight}px`, paddingY: 4 }}>
        <Card variant="outlined">
          <Stack direction="column" spacing={2}>
            <SearchInput
              placeholder={t('wholesaler.search')}
              value={query}
              onChange={evt => setQuery(evt.target.value)}
              size="small"
              fullWidth
            />
            <Stack direction="row" spacing={1}>
              <FormControl size="small" sx={{ minWidth: 200 }}>
                <InputLabel id="year-select-label">{t('wholesaler.last_benchmark_year')}</InputLabel>
                <Select
                  labelId="year-select-label"
                  id="year-select"
                  value={year}
                  label={t('wholesaler.last_benchmark_year')}
                  onChange={evt => setYear(evt.target.value === 'all' ? 'all' : Number(evt.target.value))}>
                  <MenuItem value="all">{t('global.all_female')}</MenuItem>
                  {Year.current()
                    .getLastYears(10)
                    .map(year => (
                      <MenuItem key={year} value={year}>
                        {year}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl size="small" sx={{ minWidth: 200 }}>
                <InputLabel id="sort-by-select-label">{t('global.sort_by')}</InputLabel>
                <Select
                  labelId="sort-by-select-label"
                  id="sort-by-select"
                  value={sortBy}
                  label={t('global.sort_by')}
                  onChange={evt => setSortBy(evt.target.value as WholesalerSortMethod)}>
                  <MenuItem value="name">{t('global.name')}</MenuItem>
                  <MenuItem value="last_benchmark_date_asc">{t('wholesaler.sort.last_benchmark_date_asc')}</MenuItem>
                  <MenuItem value="last_benchmark_date_desc">{t('wholesaler.sort.last_benchmark_date_desc')}</MenuItem>
                  <MenuItem value="linear_meters_asc">{t('wholesaler.sort.linear_meters_asc')}</MenuItem>
                  <MenuItem value="linear_meters_desc">{t('wholesaler.sort.linear_meters_desc')}</MenuItem>
                </Select>
              </FormControl>
            </Stack>
            <TableContainer>
              <Table
                aria-label="wholesalers table"
                sx={{ '& > tbody tr:nth-of-type(odd)': { backgroundColor: '#00000006' } }}>
                <TableHead>
                  <TableRow>
                    <TableCell>{nonBreaking(t('wholesaler.id_code'))}</TableCell>
                    <TableCell>{t('global.name')}</TableCell>
                    <TableCell>{t('global.city')}</TableCell>
                    <TableCell>{t('global.department')}</TableCell>
                    <TableCell>{t('global.address')}</TableCell>
                    <TableCell>{nonBreaking(t('wholesaler.last_benchmark'))}</TableCell>
                    <TableCell>{nonBreaking(t('wholesaler.last_benchmark_linear_meters'))}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.edges.map(wholesaler => <WholesalerRow key={wholesaler.id} wholesaler={wholesaler} />)}
                  {data?.edges.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={7} align="center">
                        {t('wholesaler.empty_for_filters')}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {(data?.pageInfo.numberOfPages ?? 0) > 1 && (
              <Box mt={2} display="flex" justifyContent="flex-end">
                <Pagination
                  count={data?.pageInfo.numberOfPages}
                  color="primary"
                  onChange={(_, value) => setPage(value)}
                />
              </Box>
            )}
          </Stack>
        </Card>
      </Container>
    </>
  );
}

export default WholesalerList;
