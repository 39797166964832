import { useEffect, useState } from 'react';
import Config from '../config';

const getOnlineStatus = async () => {
  // First, check if the browser detects network connection
  if (typeof navigator !== 'undefined' && typeof navigator.onLine === 'boolean') {
    if (!navigator.onLine) {
      return false;
    }
  }

  // If online, we further verify by pinging the server
  try {
    const response = await fetch(Config.apiBaseUrl, {
      method: 'GET',
    });
    return response.ok;
  } catch (error) {
    return false;
  }
};

const useNetwork = () => {
  const [isOnline, setIsOnline] = useState(true);

  useEffect(() => {
    const updateOnlineStatus = async () => {
      setIsOnline(await getOnlineStatus());
    };

    const handleOnline = () => updateOnlineStatus();
    const handleOffline = () => setIsOnline(false);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    // Run once on mount to check current status
    updateOnlineStatus();

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return { online: isOnline };
};

export default useNetwork;