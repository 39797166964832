import { Wholesaler } from '../../../../services/wholesaler.service';
import { TableCell, TableRow } from '@mui/material';
import { getDepartmentNameFromZip } from '../../../../helpers/departments';
import useTranslate from '../../../../hooks/useTranslate';
import { getTotalNumberOfLinearMeters } from '../../../../helpers/intervention-report';

export interface WholesalerRowProps {
  wholesaler: Wholesaler;
}

function WholesalerRow({ wholesaler }: WholesalerRowProps) {
  const { localeDate } = useTranslate();

  let totalLinearMeters = wholesaler.interventionReports[0]
    ? getTotalNumberOfLinearMeters(wholesaler.interventionReports[0])
    : 0;

  return (
    <TableRow
      key={wholesaler.id}
      component="a"
      sx={{
        '&:last-child td, &:last-child th': { border: 0 },
        textDecoration: 'none',
        userSelect: 'none',
      }}
      href={`/wholesaler/${wholesaler.id}`}>
      <TableCell component="th" scope="row">
        {wholesaler.id}
      </TableCell>
      <TableCell component="th" scope="row">
        {wholesaler.name}
      </TableCell>
      <TableCell>
        {wholesaler.city} - {wholesaler.zip}
      </TableCell>
      <TableCell>{getDepartmentNameFromZip(wholesaler.zip)}</TableCell>
      <TableCell>{wholesaler.address1}</TableCell>
      <TableCell>
        {wholesaler.interventionReports[0]?.createdAt
          ? localeDate(wholesaler.interventionReports[0].createdAt, 'short')
          : '-'}
      </TableCell>
      <TableCell>{wholesaler.interventionReports[0] ? totalLinearMeters : '-'}</TableCell>
    </TableRow>
  );
}

export default WholesalerRow;
