import { useState } from 'react';
import useTranslate from '../../../../../hooks/useTranslate';
import { Stack, Typography } from '@mui/material';
import WholesalerCard from '../../../../../components/wholesaler/WholesalerCard';
import SearchInput from '../../../../../components/ui/form/SearchInput';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { BenchmarkProposalSummary } from '../../../../../services/benchmark-proposal.service';
import { setCreateReportFormState } from '../../../../../store/slices/create-report-form.slice';
import { searchBenchmarkProposalsByWholesaler } from '../../../../../helpers/benchmark-proposal';

function CreateReportStep1ProposalTab() {
  const { t } = useTranslate();

  const dispatch = useAppDispatch();
  const { benchmarkProposalId } = useAppSelector(state => state.createReportForm);
  const { proposalsAcceptedBySalesManager } = useAppSelector(state => state.offline);

  const [query, setQuery] = useState<string>('');

  const [filteredProposals, setFilteredProposals] = useState<BenchmarkProposalSummary[]>(
    proposalsAcceptedBySalesManager,
  );

  function handleSearch(evt: React.ChangeEvent<HTMLInputElement>) {
    const newQuery = evt.currentTarget.value;
    setQuery(newQuery);
    setFilteredProposals(searchBenchmarkProposalsByWholesaler(newQuery, proposalsAcceptedBySalesManager));
  }

  return (
    <Stack direction="column" spacing={2}>
      <SearchInput
        id="search-bar"
        placeholder={t('wholesaler.search')}
        size="small"
        value={query}
        onChange={handleSearch}
      />

      {filteredProposals.map(proposal => (
        <WholesalerCard
          key={proposal.id}
          wholesaler={proposal.wholesaler}
          proposalState={proposal.state}
          selected={proposal.id === benchmarkProposalId}
          onClick={() =>
            dispatch(
              setCreateReportFormState({
                wholesalerId: proposal.wholesaler.id,
                benchmarkProposalId: proposal.id,
              }),
            )
          }
        />
      ))}

      <Stack direction="column" alignItems="center" py={1}>
        <Typography variant="caption" component="div">
          {t('wholesaler.end_of_the_list')}
        </Typography>
      </Stack>
    </Stack>
  );
}

export default CreateReportStep1ProposalTab;
