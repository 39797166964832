import { useEffect } from 'react';
import {
  AppBar,
  Box,
  Button,
  Chip,
  Hidden,
  IconButton,
  Stack,
  Tab,
  Tabs,
  Theme,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import useTranslate from '../../../../hooks/useTranslate';
import themeOptions from '../../../../theme';
import {
  BenchmarkStatement,
  useLaunchBenchmarkStatementMutation,
} from '../../../../services/benchmark-statement.service';

export type StatementTabType = 'layout' | 'statement';

export interface StatementHeaderProps {
  statement: BenchmarkStatement;
  selectedTab?: StatementTabType;
  onTabSelected?: (tab?: StatementTabType) => void;
}

function StatementHeader({ statement, selectedTab, onTabSelected }: StatementHeaderProps) {
  const { t, localeDate } = useTranslate();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const [launchBenchmarkStatement] = useLaunchBenchmarkStatementMutation();

  function handleTabSelected(tab: StatementTabType) {
    setSearchParams(prev => {
      prev.set('tab', tab);
      return prev;
    });
  }

  function submitLaunch() {
    launchBenchmarkStatement(statement.id)
      .unwrap()
      .then(response => {
        if (!response.benchmarkProposal) {
          alert(t('global.error.unknown'));
          return;
        }

        navigate(`/benchmark-proposal/${response.benchmarkProposal.id}`);
      })
      .catch(() => {
        alert(t('global.error.unknown'));
      });
  }

  useEffect(() => {
    const tabFromParams = searchParams.get('tab');
    const exists = ['layout', 'statement'].includes(tabFromParams ?? '');
    if (exists) {
      onTabSelected?.(tabFromParams as StatementTabType);
    } else {
      onTabSelected?.('layout');
    }
  }, [searchParams]);

  return (
    <AppBar position="relative" color="transparent" sx={{ backgroundColor: 'white', pt: 1 }}>
      <Toolbar>
        <Stack direction="column" spacing={1} flexGrow={1}>
          <Stack direction={isMobile ? 'column' : 'row'} alignItems="center" justifyContent="space-between" spacing={1}>
            <Stack direction="row" alignItems="center" spacing={2}>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="back"
                sx={{ mr: 1, p: 1 }}
                onClick={() => navigate(-1)}>
                <ChevronLeftIcon />
              </IconButton>
              <Typography variant={isMobile ? 'subtitle2' : 'subtitle1'} color="inherit" component="div">
                {t('statement.date', { date: localeDate(statement.createdAt, 'short') })}
              </Typography>
              <Chip size="small" color="info" label={t('statement.private')} />
            </Stack>
            {statement.benchmarkProposal == null && (
              <Button variant="contained" fullWidth={isMobile} onClick={submitLaunch}>
                {t('statement.turn_into_proposal')}
              </Button>
            )}
          </Stack>
          <Stack direction={isMobile ? 'column' : 'row'} spacing={isMobile ? 1 : 4} pb={2}>
            <div>
              <Typography variant="caption" component="small" color="text.secondary">
                {t('wholesaler.id')}
              </Typography>
              <Typography variant="body1" color={themeOptions.palette?.text?.primary}>
                <Box component="a" href={`/wholesaler/${statement.wholesaler.id}`} color="inherit">
                  {statement.wholesaler.id}
                </Box>
              </Typography>
            </div>
            <div>
              <Typography variant="caption" component="small" color="text.secondary">
                {t('wholesaler.name')}
              </Typography>
              <Typography variant="body1">{statement.wholesaler.name}</Typography>
            </div>
            <div>
              <Typography variant="caption" component="small" color="text.secondary">
                {t('global.city')}
              </Typography>
              <Typography variant="body1">
                {statement.wholesaler.city} - {statement.wholesaler.zip}
              </Typography>
            </div>
            <div>
              <Typography variant="caption" component="small" color="text.secondary">
                {t('layout.benchmark')}
              </Typography>
              <Typography variant="body1">{statement.benchmarkLayout.name}</Typography>
            </div>
          </Stack>

          <Box sx={{ marginTop: 1 }}>
            <Tabs
              orientation={isMobile ? 'vertical' : 'horizontal'}
              value={selectedTab}
              onChange={(_, value) => handleTabSelected(value)}
              aria-label="markets">
              <Tab
                value="layout"
                label={t('statement.layout_and_contact')}
                wrapped
                sx={{ maxWidth: isMobile ? 'none' : undefined }}
              />
              <Tab
                value="statement"
                label={t('statement.title')}
                wrapped
                sx={{ maxWidth: isMobile ? 'none' : undefined }}
              />
            </Tabs>
          </Box>
        </Stack>
      </Toolbar>
    </AppBar>
  );
}

export default StatementHeader;
