import { useEffect } from 'react';
import useNetwork from '../../hooks/useNetwork';
import { useAppDispatch, useAppSelector } from '../../store';
import {
  saveFormSuggestions,
  saveLayoutBenchmarks,
  saveProducts,
  saveProposalsAcceptedBySalesManager,
  saveWholesalerNetworks,
  saveWholesalers,
  updateLastSyncDate,
} from '../../store/slices/offline.slice';
import { useLazyGetAllWholesalersQuery } from '../../services/wholesaler.service';
import { useLazyGetAllWholesalerNetworksQuery } from '../../services/wholesaler-network.service';
import { useLazyGetAllLayoutBenchmarksQuery } from '../../services/layout-benchmark.service';
import { useLazyGetAllFormSuggestionsQuery } from '../../services/form-suggestion.service';
import { useLazyGetAllProductsQuery } from '../../services/product.service';
import { useLazyGetProposalsAcceptedBySalesManagerQuery } from '../../services/benchmark-proposal.service';

function DataLoader() {
  const dispatch = useAppDispatch();
  const { isAuthenticated } = useAppSelector(state => state.auth);
  const { lastSyncAt } = useAppSelector(state => state.offline);
  const { online } = useNetwork();

  const [getAllWholesalers] = useLazyGetAllWholesalersQuery();
  const [getAllWholesalerNetworks] = useLazyGetAllWholesalerNetworksQuery();
  const [getAllLayoutBenchmarks] = useLazyGetAllLayoutBenchmarksQuery();
  const [getAllProducts] = useLazyGetAllProductsQuery();
  const [getAllFormSuggestions] = useLazyGetAllFormSuggestionsQuery();
  const [getProposalsAcceptedBySalesManager] = useLazyGetProposalsAcceptedBySalesManagerQuery();

  function loadData() {
    Promise.all([
      getAllWholesalers().unwrap(),
      getAllWholesalerNetworks().unwrap(),
      getAllLayoutBenchmarks().unwrap(),
      getAllProducts().unwrap(),
      getAllFormSuggestions().unwrap(),
      getProposalsAcceptedBySalesManager().unwrap(),
    ])
      .then(responses => {
        dispatch(saveWholesalers(responses[0]));
        dispatch(saveWholesalerNetworks(responses[1]));
        dispatch(saveLayoutBenchmarks(responses[2]));
        dispatch(saveProducts(responses[3]));
        dispatch(saveFormSuggestions(responses[4]));
        dispatch(saveProposalsAcceptedBySalesManager(responses[5]));
        dispatch(updateLastSyncDate());
      })
      .catch(_ => {});
  }

  useEffect(() => {
    const lastSyncMoreThanAnHourAgo = new Date(lastSyncAt) < new Date(Date.now() - 60 * 60 * 1000);
    if (isAuthenticated && online && lastSyncMoreThanAnHourAgo) {
      loadData();
    }
  }, [isAuthenticated, online, lastSyncAt]);

  return null;
}

export default DataLoader;
