import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import Config from '../config';
import { prepareHeaders } from '../helpers/services';
import { Paginated } from './types';
import { BenchmarkProposalSummary } from './benchmark-proposal.service';

/** A contact at a wholesaler. */
export interface WholesalerContact {
  /** The first name of the contact. */
  firstName: string;
  /** The last name of the contact. */
  lastName: string;
  /** The contact's position. */
  position: string;
  /** The contact's phone number. */
  phone: string;
  /** The contact's email. */
  email: string;
}

/** A Wholesaler is a company that sells Delabie products to the end customer. */
export interface Wholesaler {
  /** The wholesaler's id. */
  id: string;
  /** The wholesaler's name. */
  name: string;
  /** The wholesaler's second name. */
  name2: string | null;
  /** The wholesaler's address line 1. */
  address1: string;
  /** The wholesaler's address line 2. */
  address2: string | null;
  /** The wholesaler's address zip code. */
  zip: string;
  /** The wholesaler's address city. */
  city: string;
  /** The wholesaler's address country. */
  country: string;
  /** The wholesaler's group. */
  group: string | null;
  /** The wholesaler's entity. */
  entity: string | null;
  /** The wholesaler's contacts. */
  contacts: WholesalerContact[];
  /** The most recent benchmark proposal for the wholesaler. */
  interventionReports: [] | [{
    createdAt: string,
    publicPlacesMeters: number,
    renovationMeters: number,
    accessMeters: number
  }];
  /** The code of the salesperson managing the wholesaler. */
  salesRepCode: string;
  /** The last time the wholesaler was updated. */
  updatedAt: string,
  /** The date the wholesaler was created. */
  createdAt: string,
  /** The date the wholesaler was deleted. */
  deletedAt: string,
}

/** A group of wholesalers. */
export interface WholesalerGroup {
  /** The name of the group. */
  name: string;
  /** The entities (subgroups) of the group. */
  entities: string[];
}

/** An API for Wholesalers. */
export const wholesalerApi = createApi({
  reducerPath: 'wholesalerApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${Config.apiBaseUrl}/wholesaler`, prepareHeaders }),
  tagTypes: ['Wholesalers', 'BenchmarkProposals'],
  endpoints: (builder) => ({
    /**
     * Get a Wholesaler by id.
     * @param id The wholesaler's id.
     */
    getWholesaler: builder.query<Wholesaler, string>({
      query: (id) => `/${id}`,
    }),

    /**
     * Get a list of Wholesalers.
     * @param page The page number to fetch.
     * @param query A search query to filter the list.
     */
    getWholesalers: builder.query<Paginated<Wholesaler>, { page?: number, query?: string } | void>({
      query: (params) => {
        const searchParams = new URLSearchParams();
        if (params?.page) searchParams.append('page', params.page.toString());
        if (params?.query) searchParams.append('q', params.query);
        return `?${searchParams.toString()}`;
      },
      providesTags: (result) =>
        result
          ? [
            ...result.edges.map(({ id }) => ({ type: 'Wholesalers' as const, id })),
            { type: 'Wholesalers', id: 'PARTIAL-LIST' },
          ]
          : [{ type: 'Wholesalers', id: 'PARTIAL-LIST' }],
    }),

    /**
     * Get all wholesalers.
     */
    getAllWholesalers: builder.query<Wholesaler[], void>({
      query: () => '/all',
      providesTags: (result) => result ? [...result.map(({ id }) => ({ type: 'Wholesalers' as const, id }))] : [],
    }),

    /**
     * Get a list of benchmark proposals for a wholesaler.
     * @param id The wholesaler's id.
     * @param page The page number to fetch.
     * @returns A list of benchmark proposals for the wholesaler.
     */
    getWholesalerBenchmarkProposals: builder.query<Paginated<BenchmarkProposalSummary>, { id: string, page?: number }>({
      query: (params) => {
        const searchParams = new URLSearchParams();
        if (params?.page) searchParams.append('page', params.page.toString());
        return `/${params.id}/benchmark-proposals?${searchParams.toString()}`;
      },
      providesTags: (result) =>
        result
          ? [
            ...result.edges.map(({ id }) => ({ type: 'BenchmarkProposals' as const, id })),
            { type: 'BenchmarkProposals', id: 'PARTIAL-LIST' },
          ]
          : [{ type: 'BenchmarkProposals', id: 'PARTIAL-LIST' }],
    }),

    /**
     * Get the list of the groups for the wholesalers of the user.
     * @returns List of groups.
     */
    getGroups: builder.query<WholesalerGroup[], void>({
      query: () => '/groups',
    }),
  }),
});

export const {
  useGetWholesalerQuery,
  useGetWholesalersQuery,
  useLazyGetWholesalerQuery,
  useLazyGetWholesalersQuery,
  useGetAllWholesalersQuery,
  useLazyGetAllWholesalersQuery,
  useGetWholesalerBenchmarkProposalsQuery,
  useGetGroupsQuery,
  useLazyGetGroupsQuery,
} = wholesalerApi;