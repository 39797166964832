import React from 'react';
import {
  Card,
  Hidden,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import useTranslate from '../../../../hooks/useTranslate';
import useNetwork from '../../../../hooks/useNetwork';
import { useGetAllPrivateStatementsQuery } from '../../../../services/benchmark-statement.service';
import PrivateStatementRow from './PrivateStatementRow';

function PrivateStatementsCard() {
  const { online } = useNetwork();
  const { t } = useTranslate();

  const { data, isLoading } = useGetAllPrivateStatementsQuery();

  if (!online || (data !== undefined && data.length === 0)) {
    return null;
  }

  return (
    <Card variant="outlined">
      <Typography variant="h6" component="h2" fontWeight="regular" mb={2}>
        {t('dashboard.private_statements')}
      </Typography>

      <TableContainer component={props => <Paper variant="outlined" {...props} />}>
        <Table
          aria-label="benchmark proposals table"
          sx={{ '& > tbody tr:nth-of-type(odd)': { backgroundColor: '#00000006' } }}>
          <TableHead>
            <TableRow>
              <TableCell>{t('global.date')}</TableCell>
              <TableCell>{t('statement.name')}</TableCell>
              <Hidden smDown>
                <TableCell>{t('statement.zip')}</TableCell>
                <TableCell>{t('statement.city')}</TableCell>
              </Hidden>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map(statement => (
              <React.Fragment key={statement.id}>
                <PrivateStatementRow statement={statement} />
              </React.Fragment>
            ))}

            {data?.length === 0 && !isLoading && (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  {t('statement.private.empty')}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
}

export default PrivateStatementsCard;
