import { useEffect, useState } from 'react';
import {
  Box,
  Card,
  Dialog,
  Divider,
  Fab,
  FormGroup,
  FormLabel,
  ImageList,
  ImageListItem,
  Paper,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import useTranslate from '../../../../hooks/useTranslate';
import { InterventionReportPhoto, InterventionReportWithSales } from '../../../../services/intervention-report.service';
import { getFullName } from '../../../../helpers/user';
import { getPhotoUrl } from '../../../../helpers/benchmark-statement';
import styled from '@emotion/styled';
import { useSearchParams } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import ProductPresentation from '../../../../components/product/ProductPresentation';
import { ProductMarket } from '../../../../services/product.service';

export interface ReportCardProps {
  report: InterventionReportWithSales;
}

function ReportCard({ report }: ReportCardProps) {
  const { t, localeDate } = useTranslate();
  const [searchParams, setSearchParams] = useSearchParams();

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const [selectedPhoto, setSelectedPhoto] = useState<InterventionReportPhoto | null>(null);
  const [selectedMarketTab, setSelectedMarketTab] = useState<ProductMarket | 'others'>('public_places');

  const productStatements = report.products.filter(item => {
    if (selectedMarketTab === 'others') return !['public_places', 'renovation', 'access'].includes(item.product.mkt1);
    return item.product.mkt1 === selectedMarketTab;
  });

  /**
   * Handle a photo selected event.
   * @param photo The photo to select.
   */
  function handlePhotoSelected(photo: InterventionReportPhoto) {
    setSearchParams(prev => {
      prev.set('photo', photo.key);
      return prev;
    });
  }

  /** Handle a photo preview close request. */
  function handlePhotoClose() {
    setSearchParams(prev => {
      prev.delete('photo');
      return prev;
    });
  }

  // Update the currently selected photo from the URL
  useEffect(() => {
    const photoKey = searchParams.get('photo');
    const photo = report.photos.find(p => p.key === photoKey);
    setSelectedPhoto(photo ?? null);
  }, [searchParams]);

  return (
    <>
      <Card variant="outlined" component="section">
        <Typography variant="h6" component="h1" mb={2}>
          {t('report.title')} {localeDate(report.createdAt, 'short')}
        </Typography>
        <Divider />
        <form>
          {report.isImplementation && (
            <Paper variant="outlined" sx={{ padding: 2, marginTop: 2 }}>
              <FormGroup>
                <FormLabel component="legend" sx={{ marginBottom: 2 }}>
                  {t('report.number_of_meters')}
                </FormLabel>
                <Stack direction={isMobile ? 'column' : 'row'} spacing={2}>
                  <TextField
                    type="number"
                    variant="outlined"
                    label={t('market.public_places')}
                    value={report.publicPlacesMeters}
                    fullWidth
                    disabled
                  />
                  <TextField
                    type="number"
                    variant="outlined"
                    label={t('market.renovation')}
                    value={report.renovationMeters}
                    fullWidth
                    disabled
                  />
                  <TextField
                    type="number"
                    variant="outlined"
                    label={t('market.access')}
                    value={report.accessMeters}
                    fullWidth
                    disabled
                  />
                </Stack>
              </FormGroup>
            </Paper>
          )}

          {report.isTraining && (
            <Paper variant="outlined" sx={{ padding: 2, marginTop: 2 }}>
              <FormGroup>
                <FormLabel component="legend" sx={{ marginBottom: 2 }}>
                  {t('report.type.training')}
                </FormLabel>
                <Stack direction={isMobile ? 'column' : 'row'} spacing={2}>
                  <TextField
                    type="number"
                    variant="outlined"
                    label={t('report.number_of_trained_people')}
                    value={report.numberOfTrainedPeople}
                    fullWidth
                    disabled
                  />
                </Stack>
              </FormGroup>
            </Paper>
          )}

          {report.isAnimation && (
            <Paper variant="outlined" sx={{ padding: 2, marginTop: 2 }}>
              <FormGroup>
                <FormLabel component="legend" sx={{ marginBottom: 2 }}>
                  {t('report.type.animation')}
                </FormLabel>
                <Stack direction={isMobile ? 'column' : 'row'} spacing={2}>
                  <TextField
                    variant="outlined"
                    label={t('report.animation_type')}
                    value={t(`report.animation_type.${report.animationType!}`)}
                    fullWidth
                    disabled
                  />
                </Stack>
              </FormGroup>
            </Paper>
          )}

          <Stack direction={isMobile ? 'column' : 'row'} spacing={2} mt={2}>
            <Paper variant="outlined" sx={{ padding: 2, marginTop: 2, flexGrow: 1 }}>
              <FormLabel component="legend" sx={{ marginBottom: 2 }}>
                {t('comments.title')}
              </FormLabel>
              <TextField
                variant="outlined"
                value={report.comments ?? 'Aucun'}
                multiline
                minRows={5}
                fullWidth
                disabled
              />
            </Paper>
            {report.isImplementation && (
              <Paper variant="outlined" sx={{ padding: 2, marginTop: 2, flexGrow: 1 }}>
                <FormLabel component="legend" sx={{ marginBottom: 2 }}>
                  {t('user.role.sales_coordinator')}
                </FormLabel>
                <TextField
                  variant="outlined"
                  label={t('user.full_name')}
                  value={getFullName(report.coordinator)}
                  fullWidth
                  disabled
                />
              </Paper>
            )}
          </Stack>
        </form>

        {report.isImplementation && (
          <Paper variant="outlined" sx={{ padding: 2, marginTop: 2 }}>
            <FormLabel component="legend" sx={{ marginBottom: 2 }}>
              {t('photos.title')}
            </FormLabel>
            <ImageList cols={isMobile ? 3 : isTablet ? 6 : 10} gap={4}>
              {report.photos.map(photo => (
                <PhotoButton key={photo.key} type="button" onClick={() => handlePhotoSelected(photo)}>
                  <ImageListItem>
                    <img src={getPhotoUrl(photo.key)} loading="lazy" />
                  </ImageListItem>
                </PhotoButton>
              ))}
            </ImageList>
          </Paper>
        )}

        {report.isImplementation && (
          <Paper variant="outlined" sx={{ padding: 2, marginTop: 2 }}>
            <FormLabel component="legend" sx={{ marginBottom: 2 }}>
              {t('product.implanted_list')}
            </FormLabel>

            <Box sx={{ marginTop: 1, marginBottom: 2 }}>
              <Tabs
                variant="scrollable"
                value={selectedMarketTab}
                onChange={(_, value) => setSelectedMarketTab(value)}
                aria-label="markets">
                <Tab value="public_places" label={t('market.public_places')} wrapped />
                <Tab value="renovation" label={t('market.renovation')} wrapped />
                <Tab value="access" label={t('market.access')} wrapped />
                <Tab value="others" label={t('market.others')} wrapped />
              </Tabs>
              <Divider />
            </Box>

            <TableContainer component={props => <Paper variant="outlined" {...props} />}>
              <Table
                aria-label="products table"
                sx={{ '& > tbody tr:nth-of-type(odd)': { backgroundColor: '#00000006' } }}>
                <TableHead>
                  <TableRow>
                    <TableCell>{t('product.name')}</TableCell>
                    <TableCell>{t('global.quantity_short')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {productStatements.map(stmt => (
                    <TableRow key={stmt.product.ref}>
                      <TableCell>
                        <ProductPresentation product={stmt.product} redirectToDetailsOnClick />
                      </TableCell>
                      <TableCell>{stmt.quantity}</TableCell>
                    </TableRow>
                  ))}
                  {productStatements.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={2} align="center">
                        {t('product.empty')}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        )}
      </Card>

      <Dialog fullScreen open={selectedPhoto !== null} onClose={handlePhotoClose}>
        {selectedPhoto !== null && (
          <PhotoViewerContainer>
            <PhotoViewer style={{ backgroundImage: `url(${getPhotoUrl(selectedPhoto.key)})` }} />
            <Fab
              color="primary"
              size="medium"
              sx={{ position: 'absolute', top: 20, right: 20 }}
              aria-label="close"
              onClick={handlePhotoClose}>
              <CloseIcon />
            </Fab>
          </PhotoViewerContainer>
        )}
      </Dialog>
    </>
  );
}

const PhotoButton = styled.button`
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
`;

const PhotoViewerContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const PhotoViewer = styled.div`
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
`;

export default ReportCard;
