/**
 * A file with its base64 representation.
 */
export interface FileBase64 {
  name: string;
  type: string;
  size: number;
  base64: string;
}

/**
 * Convert a FileBase64 to a Blob.
 * @param file The FileBase64 to convert to a Blob.
 * @returns The Blob representation of the FileBase64.
 */
export function fileBase64ToBlob(file: FileBase64): Blob {
  // Remove the base64 header, like "data:image/jpeg;base64,"
  const base64WithoutHeader = file.base64.split(',')[1];

  // Convert base64 to binary data (Blob)
  const binary = atob(base64WithoutHeader);
  const arrayBuffer = new ArrayBuffer(binary.length);
  const uint8Array = new Uint8Array(arrayBuffer);

  for (let i = 0; i < binary.length; i++) {
    uint8Array[i] = binary.charCodeAt(i);
  }

  return new Blob([uint8Array], { type: file.type });
}